import { CircularProgress, Switch, Typography } from '@mui/material';
import React, { Component } from 'react'
import { getFormattedDateString, ip_adress } from './App';
import { Spellcheck } from '@mui/icons-material';
import { setSpellCheck } from './actions';
import { connect } from 'react-redux';
import { LOCALSTORAGE_KEYS } from '.';
import { toast } from 'react-toastify';

export class DailyHoursStatus extends Component {
    
    constructor(props) {
        super(props);
        let spellCheckActive = localStorage.getItem(LOCALSTORAGE_KEYS.SPELLCHECK) === "true" ? true : false;
        this.state = {
            isBankHoliday: true,
            // dailyHoursReached : false,
            actualHours: 0.0,
            expectedHours: 0.0,
            loading: true,
            spellCheckEnabled: spellCheckActive
        }

        this.loadHours();
        this.props.dispatchSetSpellCheck(spellCheckActive);
    }
    componentDidUpdate(prevprops) {
        // 
        if (prevprops.currentDate !== this.props.currentDate || prevprops.actualize !== this.props.actualize || prevprops.rowCount !== this.props.rowCount) {
            this.loadHours();
        }
    }
    loadHours() {
        
        if (!isNaN(this.props.currentDate)) {
            this.setState({ loading: true });
            
            
            let url = ip_adress + ':3001/getdayhours?d=' + getFormattedDateString(this.props.currentDate);
            
            fetch(url, { credentials: "include" })
                .then(response => {
                    
                    if (response.ok) {
                        return response.json();
                    } else {
                        
                        this.setState({loading: false});
                        toast.error(`Fehler beim Laden der Stunden (${response.status})`);
                        return null;
                    }
                }).then(result => {
                    console.log("GETDAYHOURSRESULT: ", result);
                    if (result != null) {
                        
                        this.setState({
                            actualHours: result.ist_zeit != null ? result.ist_zeit : 0.0,
                            expectedHours: result.soll_zeit != null && result.is_holiday == 0 ? result.soll_zeit : 0.0,
                            isBankHoliday: result.is_holiday == 1,
                            loading: false
                        })
                    }
                })
                .catch(e => {
                    toast.error("Fehler beim Laden der Stunden.");
                    console.log("Error loading hours: ", e);
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    handleSpellCheckChange = () => {
        let spellCheck = !this.state.spellCheckEnabled;
        localStorage.setItem(LOCALSTORAGE_KEYS.SPELLCHECK, spellCheck);
        this.props.dispatchSetSpellCheck(spellCheck);
        this.setState({spellCheckEnabled: spellCheck });
    };

    render() {
        return (

            <div style={{ width: "100%", display: "flex", marginLeft: 2.5, marginTop: 10, justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "95%", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <div style={{ width: "90%", display: "flex", alignItems: "center", flexDirection: "row" }}>
                        <Typography style={{ fontFamily: "monospace", color: "#353535" }}>
                            {this.state.actualHours >= this.state.expectedHours || this.state.isBankHoliday ? "✅" : "❗️"}
                        </Typography>
                        {this.state.loading ? <CircularProgress style={{ marginLeft: 10 }} size={16} /> :
                            <Typography style={{ marginLeft: 10, fontSize:14, color: '#353535', fontFamily: "monospace" }}>
                                {this.state.actualHours.toFixed(2)} / {this.state.expectedHours.toFixed(2)} Stunden
                            </Typography>
                        }
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent:"right", flex:1, minWidth:250,}}>
                        <Typography style={{ color: '#353535',  fontSize:14, fontFamily: "monospace" }}>
                            KI-Unterstützung:
                        </Typography>
                        <Switch
                            checked={this.state.spellCheckEnabled}
                            onChange={this.handleSpellCheckChange}
                            color="primary"
                        />
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    spellCheck: state.spellCheckEnabled
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSpellCheck: (value) => dispatch(setSpellCheck(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyHoursStatus);
//export default DailyHoursStatus