import { Autocomplete, Button, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'

const testData = {
    "lieferNr": "0010233",
    "thema": "my thema3",
    "datum": 1726123862000,
    "besuchsart": 1,
    "sysId": 1,
    "sysVersion": 1,
    "status": "A",
    "anlVerk": "999",
    "anlDatum": 1726123862000,
    "modVerk": "999",
    "modDatum": 1728566110877,
    "teilnehmer": [
        {
            "sysId": 51689810,
            "sysVersion": 0,
            "gespraechsProtokoll": 1,
            "lieferNr": "-99",
            "id": 0,
            "verkNr": "987",
            "status": "A",
            "anlVerk": "999",
            "anlDatum": 1728556497040,
            "modVerk": "999",
            "modDatum": 1728556497040
        },
        {
            "sysId": 51689810,
            "sysVersion": 0,
            "gespraechsProtokoll": 1,
            "lieferNr": "1508",
            "id": 15,
            "verkNr": null,
            "status": "A",
            "anlVerk": "999",
            "anlDatum": 1728556497040,
            "modVerk": "999",
            "modDatum": 1728556497040
        },
        {
            "sysId": 51689810,
            "sysVersion": 0,
            "gespraechsProtokoll": 1,
            "lieferNr": "1508",
            "id": 16,
            "verkNr": null,
            "status": "A",
            "anlVerk": "999",
            "anlDatum": 1728556497040,
            "modVerk": "999",
            "modDatum": 1728556497040
        }
    ]
}
export class Test extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            interneTeilnehmer: [],
            externeTeilnehmer: [],
            teilnehmer: [],
        }
    }
    loadTeilnehmer(id = null, lieferNr = null, verkNr = null) {
        let testNames = ["Hans", "Peter", "Müller", "Ursula"];
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(testNames[Math.floor(Math.random() * testNames.length)]);
            }, Math.floor(Math.random() * (400 - 200 + 1)) + 200);
        })
    }
    componentDidMount() {
        let promises = [];
        testData.teilnehmer.forEach((teilnehmer) => {
            promises.push(this.loadTeilnehmer(
                teilnehmer.id != null && teilnehmer.id !== "" && teilnehmer.id !== "0" ? teilnehmer.id : null,
                teilnehmer.lieferNr != null && teilnehmer.lieferNr !== "" && teilnehmer.lieferNr !== "0" ? teilnehmer.lieferNr : null,
                teilnehmer.verkNr != null && teilnehmer.verkNr !== "" && teilnehmer.verkNr !== "0" ? teilnehmer.verkNr : null,
            ));
        })
        Promise.all(promises).then((values) => {

            testData.teilnehmer = testData.teilnehmer.map((teilnehmer, index) => {
                return { ...teilnehmer, name: values[index] }
            })
            let internTeilnehmer = testData.teilnehmer?.filter((teilnehmer) => {
                console.log("TEILNEHMER: ",teilnehmer.verkNr);
                return teilnehmer.verkNr == null || teilnehmer.verkNr === "" || teilnehmer.verkNr === "0"});
            console.log("INTERNTEILNEHMER: ",internTeilnehmer)
            let externeTeilnehmer = testData.teilnehmer?.filter((teilnehmer) => !(teilnehmer.verkNr == null || teilnehmer.verkNr === "" || teilnehmer.verkNr === "0"));
            this.setState({
                teilnehmer: testData.teilnehmer,
                interneTeilnehmer: internTeilnehmer,
                externeTeilnehmer: externeTeilnehmer
            });
        })
    }
    render() {
        return (
            <div>
                <Typography>Interne Teilnehmer:</Typography>
                {this.state.interneTeilnehmer?.length > 0 ?
                    this.state.interneTeilnehmer.map((teilnehmer, index) => {
                        return <Typography key={"INTERN_" + index}>{teilnehmer.id}: {teilnehmer.name}</Typography>
                    })
                    : <Typography>Keine Internen Teilnehmer</Typography>
                }


                <Typography>Externe Teilnehmer:</Typography>
                {this.state.externeTeilnehmer?.length > 0 ?
                    this.state.externeTeilnehmer.map((teilnehmer, index) => {
                        return <Typography key={"INTERN_" + index}>{teilnehmer.verkNr}: {teilnehmer.name}</Typography>
                    })
                    : <Typography>Keine Externen Teilnehmer</Typography>
                }
                <Button onClick={() => { }}>Senden</Button>
            </div>
        )
    }
}

export default Test