import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    backdrop: {
        backgroundColor: 'transparent', // Setzt den Hintergrund des Backdrops auf transparent
    },
    paper: {
        backgroundColor: 'transparent', // Setzt den Hintergrund des Dialogs auf transparent
        boxShadow: 'none', // Entfernt den Box-Schatten des Dialogs
    },
    dialogContent: {
        backgroundColor: 'transparent', // Setzt den Hintergrund des Dialog-Inhalts auf transparent
    },
}));

const LoadingDialog = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={props.open}
                
                classes={{
                    paper: classes.paper, // Fügt die benutzerdefinierte Papier-Klasse hinzu
                }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:120, height:120, backgroundColor:"rgba(0,0,0,0.4)", borderRadius:20}}>
                        <CircularProgress style={{ width: 40, height: 40, color: '#0052a3'}} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};
// 
export default LoadingDialog;
