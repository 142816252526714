import React, { Component } from 'react'
import { getColor, limit100Chrs } from './TaskInput'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from '@mui/material';

export class TaskInputSuggestions extends Component {
    constructor(props) {
        super(props)
        this.suggestionAreaRef = React.createRef();
    }

    
    componentDidUpdate(prevProps) {
        if (prevProps.focusIndex !== this.props.focusIndex) {
            if (this.props.focusIndex >= 0 && this.props.focusIndex < this.props.data.length) {
                // Scroll to focused suggestion if it is not in view
                this.suggestionAreaRef.current.children[this.props.focusIndex].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
        }
    }
    render() {
        return (
            <div>

                <div ref={this.suggestionAreaRef} className='taskInputSuggestionArea' style={{ display: "flex", overflow: "scroll", justifyContent: "flex-start", alignItems: "center" }}>
                    {
                        this.props.data.map((suggestion, index) => {
                            return (
                                <div className='suggestionItem' key={index} style={{ height: 25, display: "flex", border: index === this.props.focusIndex ? "2px solid #76b5cf" : "none", borderRadius: 5, marginLeft: index > 0 ? 10 : 0, justifyContent: "center", alignItems: "center", padding: 10, backgroundColor: getColor(suggestion.type) }} onClick={() => this.props.onClick(index)}>
                                    {/* <p class="normal-cursor" aria-multiline={false} style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>{suggestion.value}{suggestion.matchcode ? ` (${suggestion.matchcode})` : ""}</p> */}
                                    <p class="normal-cursor" aria-multiline={false} style={{ margin: 0, whiteSpace: "nowrap", fontFamily: 'monospace', padding: 0, fontSize: 16 }}>
                                        {limit100Chrs(suggestion.value+(suggestion.matchcode ? ` (${suggestion.matchcode})` : ""))}
                                        </p>
                                </div>
                            )
                        })
                    }
                </div>

                <div style={{ position: "absolute" }}>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row", marginTop: 5 }}>
                        <Typography style={{ color: '#676767', fontSize: 10, fontFamily: "monospace", whiteSpace: 'nowrap' }}>Drücke TAB</Typography>
                        <KeyboardTabIcon style={{ color: '#000000', width: 25, height: 25, marginLeft: 10, opacity: 0.6 }} />
                        <Typography style={{ color: '#676767', fontSize: 10, fontFamily: "monospace", whiteSpace: 'nowrap' }}>  um es zu übernehmen...</Typography>
                        <Typography style={{ color: '#676767', fontSize: 10, fontFamily: "monospace", whiteSpace: 'nowrap', marginLeft: 10 }}>Zum Auswählen drücke Pfeil nach unten</Typography>
                        <KeyboardArrowDownIcon style={{ color: '#000000', width: 30, height: 30, marginLeft: 5, opacity: 0.6 }} />
                        <Typography style={{ color: '#676767', fontSize: 10, fontFamily: "monospace", whiteSpace: 'nowrap',marginLeft: 5 }}>und navigiere dann mit Pfeil nach rechts und Pfeil nach links</Typography>
                        <KeyboardArrowRightIcon style={{ color: '#000000', width: 25, height: 25, marginLeft: 5, opacity: 0.6 }} />
                        <KeyboardArrowLeftIcon style={{ color: '#000000', width: 25, height: 25, marginLeft: 0, opacity: 0.6 }} />
                        <Typography style={{ color: '#676767', fontSize: 10, fontFamily: "monospace", whiteSpace: 'nowrap' }}>  Für Referenz Doppelbindestrich (--) eingeben.</Typography>
                    </div>

                </div>
                <div style={{ display: 'flex' }}>
                    <p></p>
                </div>


                {/* <div style={{ display: "flex", alignItems:"center", flexDirection: "row", marginTop:5 }}>
                    <p style={{color:'#676767', fontSize:10, fontFamily:"monospace" }}>Drücke TAB</p>
                    <img  src={require('../media/icons/key.png')} alt="tab" style={{ width: 25, height: 25, marginLeft: 10, opacity:0.6 }} onClick={this.props.onPlusClick} />
                    <p style={{color:'#676767', whiteSpace:'pre', fontSize:10, fontFamily:"monospace"}}>  um es zu übernehmen...</p>
                    <p style={{color:'#676767', whiteSpace:'pre', fontSize:10, fontFamily:"monospace"}}>  Für Referenz Doppelbindestrich (--) eingeben.</p>
                </div> */}

            </div>

        )
    }
}

export default TaskInputSuggestions