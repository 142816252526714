import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import React, { Component } from 'react';
import { ip_adress, loggedin } from "./App";
import { ToastContainer, toast } from 'react-toastify';
import { LOCALSTORAGE_KEYS } from '.';

function handle(error){
  
  window.location.href = '/';
}
function handleLogout() {
  const options = {
    method: 'GET',
    // body: params,
    credentials: "include",
  };
  fetch(ip_adress + ':3001/logout', options).then(res => res.json()).then(data => {
    if (data.Status === 'Success') {
      localStorage.removeItem(LOCALSTORAGE_KEYS.SPELLCHECK);
      window.location.href = '/';
    } else {
      //alert('Error');
      toast.info('Error');
    }
  }).catch(err => handle(err))


}


export class Header extends Component {
  state = {

    logged: loggedin,
  };


  render() {

    return (

      <AppBar position="static" style={{ background: '#58ACFF', height: 70, width: '100%' ,justifyContent: "center", alignItems: "center"}} >
        <Box id="headerbox" sx={{}} style={{ height: 250, width: '95%' }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <img src={require("./media/bsb-logo.png")} width={153} height={60} alt="Logo" />
            </Box>

            <Box style={{ flex: 6, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography>{this.props.title}</Typography>
            </Box>

            <Box style={{ flex: 1 , display: "flex",justifyContent: 'flex-end'}}>
              {!this.props.withoutLogout && (
                <Button id='logoutButton'
                  style={{ background: '#3e90f6' }}
                  onClick={handleLogout}
                  variant="contained"
                >
                  <LogoutIcon />
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <ToastContainer
                    position="bottom-left"
                    closeOnClick
                />


      </AppBar>


    )
  }
}

export default Header