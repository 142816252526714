import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes } from 'react-router-dom';


import { Login } from "./Login";
import './App.css';
import { Home } from "./Home";
import NotFoundPage from "./NotFoundPage";
import TwoFAGen from "./TwoFAGen";
import Test from "./Test";



// chronos@ubuntu:~/ftp/files$ pm2 serve chronosweb/ 8080 --name "web" --watch

export default function App() {
  return (
    <BrowserRouter>
      <div className="maxwidth">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/tools/2fagenerator" element={<TwoFAGen/>}/>
          <Route exact path="/test" element={<Test/>}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export var ip_adress;
if(process.env.REACT_APP_BUILDTYPE === "DEV"){
  ip_adress = process.env.REACT_APP_DEV_IP
}else{
  ip_adress = process.env.REACT_APP_IP;
}
console.log("SHOW ENV VARIABLES: ", process.env.REACT_APP_BUILDTYPE, ", APP_IP: ", process.env.REACT_APP_IP, " DEV_IP: ", process.env.REACT_APP_DEV_IP);
export function getFormattedDateString(date = new Date()){
  return date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+(date.getDate())).slice(-2)
}
if (ip_adress === undefined) 
{
  console.log("IP ADRESS NOT SET, SETTING TO LOCALHOST");
    ip_adress = 'localhost';
}

export var puser = "";
export var ppassword = "";
export var counter = 0;

export function ssetCounter(c) {
  counter = c;
}

export function ssetPuser(u) {
  puser = u;
}

export function ssetPpassword(p) {
  ppassword = p;
}

export var loggedin = false;
export function ssetLoggedin(p) {
  loggedin = p;
}

