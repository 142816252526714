import React, { Component } from 'react'
import Header from './Header'
import { Typography } from '@mui/material'



export class NotFoundPage extends Component {
    render() {
        return (
            <div style={{ width: "100vw", height: "100vh" }}>
                <Header title="BSB Stundenprogramm" />
                <div style={{ width: "100%", height: "100%", paddingTop: 160, flexDirection: "column", display: "flex", alignItems: "center", }}>
                    <img src={require("./media/img/pedro-raccoon-raccoon.gif")} style={{ width: 320, height: 320, borderRadius: 640 }} />
                    <Typography style={{ marginTop: 48, fontWeight: "bold", fontSize: 24, fontFamily: "monospace" }}>Die Seite existiert nicht!</Typography>
                </div>
            </div>
        )
    }
}

export default NotFoundPage