import React, { Component, useEffect, useRef, useState } from 'react'
import './TaskInput.css'; // Import your CSS file
import TaskInputLegend from './TaskInputLegend';
import TaskInputSuggestions from './TaskInputSuggestions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Add, } from '@mui/icons-material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

import updateLocale from "dayjs/plugin/updateLocale";
import { Button } from '@mui/material';
import { convertToFourDigitYear, dateGerToEng, formatDate } from '../DateFunctions';
import 'react-toastify/dist/ReactToastify.css';
import { ip_adress } from ".././App";
import { connect } from 'react-redux';
import {
    setActualizeTable,
    setCustomerData,
    setSelection,
} from '.././actions';
import { Msg } from '../Home';
import TextDialogApp from '.././TextDialog';
import LoadingDialog from '../LoadingDialog';
import { toast } from 'react-toastify';
const BSB_CUSTOMER_NUMBER = 1984;


const tagReplace = /\*\*\*/g;
const tagAndUntagReplacer = /\*\*\*|\*\*/g;
const untaggedReplacer = /\*\*/g;
const HOURS_LABEL = "Stunden"
const allowedCharactersPattern = /^[A-Za-z0-9 äöüßÄÖÜß!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]$/;
export const tagTextMatcher = /^\*\*\*.*\*\*\*$/;
const untaggedTextMatcher = /^\*\*.*\*\*$/;
// const DATE_PATTERN =    /^(.*?)(\d{2}\.\d{2}\.\d{4}|\d{2}\.\d{2}\.\d{2})?((\s?)*-(\s?)*\d{2}\.\d{2}\.\d{4}|\d{2}\.\d{2}\.\d{2})(.*?)$/;
const DATE_PATTERN = /^(.*?)(\d{2}\.(0[1-9]|1[0-2])\.\d{4}|\d{2}\.(0[1-9]|1[0-2])\.\d{2})?((\s?)*-(\s?)*\d{2}\.(0[1-9]|1[0-2])\.\d{4}|\d{2}\.(0[1-9]|1[0-2])\.\d{2})(.*?)$/;

const HOURS_PATTERN = /^(.*?)(0,(?:25|50|5|75)|[1-9]\d*(?:,(?:25|50|5|75))?)\s?(Std|std|Stunden|stunden)?(.*?)$/;
// Safari 3.0+ "[object HTMLElementConstructor]" 
// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
export const TI_TYPES = {
    TEXT: 0,
    HOURS: 1,
    DATE: 2,
    TASK: 3,
    TASK_TYPE: 4,
    PLACE: 5,
    CUSTOMER: 6,
    REF: 7,
}
export const TI_COLORS = {
    TEXT: { color: '#353535', bgColor: 'transparent' },
    HOURS: { color: '#252525', bgColor: '#A9D6E5' },
    DATE: { color: '#353535', bgColor: '#D1E8E2' },
    TASK: { color: '#353535', bgColor: '#FFD6A5' },
    TASK_TYPE: { color: '#353535', bgColor: '#aad4a5' },
    PLACE: { color: '#353535', bgColor: '#E7E8D1' },
    CUSTOMER: { color: '#353535', bgColor: '#E8D1D1' },
    // REF: {color: '#353535', bgColor:"#7DCEA0"}
    REF: { color: '#353535', bgColor: "#AEB6BF" }

}

export function limit100Chrs(p) {
    const limit = 100
    const l = p.length
    if (l < limit) return p
    var s = p.substring(0, limit - 4) + " ..."

    return s
}

const processArray = (array) => {

    const newarray = array.map(item => {
        let { value } = item;
        let tagexist = value.startsWith("*** ") && value.endsWith(" ***");

        if (tagexist) {
            value = value.slice(4, -4); // Entferne "*** " und " ***"
        }

        value = limit100Chrs(value); // Kürze den String auf 100 Zeichen

        if (tagexist) {
            value = `*** ${value} ***`; // Füge "*** " und " ***" wieder hinzu
        }

        return {
            ...item,
            value: value
        };
    });

    return newarray
};

// export function Msg(message) {
//     toast.info(message);

// }

export function getColor(type) {
    switch (type) {
        case TI_TYPES.TEXT:
            return TI_COLORS.TEXT.bgColor;
        case TI_TYPES.HOURS:
            return TI_COLORS.HOURS.bgColor;
        case TI_TYPES.DATE:
            return TI_COLORS.DATE.bgColor;
        case TI_TYPES.TASK:
            return TI_COLORS.TASK.bgColor;
        case TI_TYPES.PLACE:
            return TI_COLORS.PLACE.bgColor;
        case TI_TYPES.CUSTOMER:
            return TI_COLORS.CUSTOMER.bgColor;
        case TI_TYPES.TASK_TYPE:
            return TI_COLORS.TASK_TYPE.bgColor;
        case TI_TYPES.REF:
            return TI_COLORS.REF.bgColor;
        default:
            return 'skyblue';
    }
}


dayjs.extend(updateLocale);
dayjs.updateLocale('de', { weekStart: 1 });



function addRow(data) {
    return new Promise((resolve, reject) => {
        console.log("....addRow: ", data);
        fetch(ip_adress + ':3001/newrow', { method: 'POST', credentials: "include", body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    resolve();
                } else {
                    reject();
                }
            }).catch(error => {
                reject();
                console.log("Error while addRow: ", error);
                Msg("Beim Hinzufügen von Stunden ist ein Fehler aufgetreten!");
            });
    })

}
// const createRow = async (data) => {
//     var result = "";
//     const options = {
//         method: 'POST',
//         body: JSON.stringify(data),
//         credentials: "include",
//         headers: { 'Content-Type': 'application/json' }
//     };
//     //Todo newrow nuß akzeptieren, dass ein boolescher query-parameter spellcheck übergeben wird.
//     // sofern dieser wert gesetzt ist, muss der alte text und die korrigierte version zurückgesendet werden
//     await 
//     return result

// };


const addVacation = async (data) => {


    var rows = await createVacation(data)

    return rows

}

const createVacation = async (data) => {

    var result = "";
    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: "include",
        headers: { 'Content-Type': 'application/json' }
    };

    await fetch(ip_adress + ':3001/setvacation', options)
        .then(response => response.json())
        .then(data => {

            if (data.length !== 0) {
                result = data;
            }
        })
        .catch(error => {
            Msg("createVacation Serverfehler !!!!")
        });

    return result
};

const updateRow = async (data, props, currentRow) => {

    var rows = await createUpdateRow(data, props, currentRow);

    return rows
}

const createUpdateRow = async (data, props, row) => {
    var currentRow
    if (row !== undefined) {
        currentRow = row
    } else {
        currentRow = props.currentRow
    }

    var newData = { ...data }

    if (newData.time == null) {
        newData.time = props.currentRow.hours

    }
    if (newData.ref === "") {
        newData.ref = currentRow.ref

    }
    if (newData.place === "") {
        var place = currentRow.location.split(" ")[0]
        for (const key in props.places) {
            if (props.places[key].name === place) {
                newData.place = props.places[key].alias;
                break
            }
        }



    }

    if (newData.task !== null) {
        newData.taskType = props.tasks[newData.task].art;
        newData.customer = props.tasks[newData.task].kunde;

    } else if (newData.customer !== "") {
        if (newData.taskType === null) {
            newData.taskType = "DNB"
            newData.task = "dummy"
        }
    }
    if (newData.customer === "") {
        var customer = currentRow.customer
        for (const key in props.customers) {
            if (props.customers[key].name === customer) {
                newData.customer = key;
                break
            }
        }

    }
    if (newData.taskType === null) {
        newData.taskType = currentRow.type
    }
    if (newData.task === null) {
        var task = currentRow.task
        for (const key in props.tasks) {
            if (props.tasks[key].text === task) {
                newData.task = key;
                break
            }
        }
    }
    if (newData.task === "dummy") {
        newData.task = null
    }
    if (newData.description === "") {
        newData.description = currentRow.taskdescription
    }
    if (newData.id === undefined) {
        newData = { ...newData, id: currentRow.dbId };
        newData = { ...newData, pos: currentRow.dbPos };
    }
    // if (newData.id === 0) {
    //     newData = { ...newData, id: currentRow.dbId };
    //     newData = { ...newData, pos: currentRow.dbPos };
    // }



    var result = "";
    const options = {
        method: 'POST',
        body: JSON.stringify(newData),
        credentials: "include",
        headers: { 'Content-Type': 'application/json' }
    };

    await fetch(ip_adress + ':3001/rowupdate', options)
        .then(response => response.json())
        .then(data => {

            if (data.length !== 0) {
                result = data;
            }
        })
        .catch(error => {
            Msg("createUpdateRow Serverfehler !!!!")
            props.deactivateEditMode();
        });

    return result
};
/**
 * This component is the main component for the task input and needs 7 props:
 * @param {Object} tasks 
 * @param {Object} customers 
 * @param {Object} places
 * @param {Object} taskTypes
 * @param {Date} currentDate
 * @param {Function} setCurrentDate
 * @param {Object} daysOfMonth
 * All these objects needs to Objects of Objects whereas the main object must be the id on the database
 * e.g. customer { 1: { name: "name: "BSB Bremer Software & Beratungs GmbH"" }, 2: { name: "Max Mustermann" } }
 * to see a detailed structure of the objects, see the constants at the bottom of this file!
 */


export class TaskInput extends Component {

    constructor(props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
        this.textInput = React.createRef();
        this.datePickerRef = React.createRef();
        this.state = {
            task: '',
            txtFieldValueFields: [
                { value: "", type: TI_TYPES.TEXT },
            ],
            suggestions: [

            ],
            suggestionFocusIndex: -1,
            // currentDate: dayjs(new Date().toISOString().split('T')[0]),
            legendError: false,
            datePickerOpen: false,
            currentRow: this.props.currentRow,
            textDialogData: null,
            lastData: null,
            isLoading: false

        }
        this.lastCaretPosition = -1;
        this.lastClickTime = -1;
        // var FILTERED_TASKS = { ...ALL_TASKS }
        this.FILTERED_TASKS = { ...props.tasks }

    }

    componentDidUpdate(prevProps) {
        const { customerData, dispatchSetCustomerData } = this.props;

        if (Object.keys(this.FILTERED_TASKS).length == 0) {
            this.FILTERED_TASKS = { ...this.props.tasks };
        }
        if (prevProps.currentRow !== this.props.currentRow) {
            if (this.state.currentRow !== this.props.currentRow) {

                this.setState({ currentRow: this.props.currentRow });
                this.setData(this.props.currentRow)
                this.scrollToEnd()
                //this.textInput.current.focus()
            }

        }
        if (prevProps.updateTextData !== this.props.updateTextData && this.props.updateTextData.text !== "") {

            this.state.lastData.description = this.props.updateTextData.text
            this.state.lastData.spellCheck = false
            this.state.lastData.id = this.props.updateTextData.id
            this.state.lastData.pos = 1

            this.state.currentRow = this.props.rows.find(item => item.id = this.props.updateTextData.id + '_1')
            //  
            var rowsPromise = updateRow(this.state.lastData, this.props, this.state.currentRow);
            rowsPromise.then((data) => {
                // 
                setTimeout(() => {
                    this.handleActualizeTable(data)
                }, 100);
            });
        }
        if (prevProps.txtFieldValue !== this.props.txtFieldValue && this.props.txtFieldValue !== null) {


            let t = [
                {
                    value: `*** ${this.props.txtFieldValue.value.replace(/\r\n/g, ' ')} ***`,
                    type: this.props.txtFieldValue.type,
                    id: this.props.txtFieldValue.id
                },
                {
                    value: " ",
                    type: 0
                }
            ];
            let flag = false
            let istask = this.props.txtFieldValue.type === TI_TYPES.TASK
            this.state.txtFieldValueFields.forEach((item) => {

                if (item.type === TI_TYPES.TASK || item.type === TI_TYPES.CUSTOMER) {
                    flag = true
                } else if (item.type === TI_TYPES.TASK_TYPE && istask) {
                    flag = true
                } else {
                    if (!flag) {
                        t.push(item)
                    } else {
                        flag = false
                    }
                }
            })

            t = processArray(t);
            this.setState({
                txtFieldValueFields: t

            });
            this.textInput.current.focus()
        }

        if (prevProps.txtFieldValueDel !== this.props.txtFieldValueDel && this.props.txtFieldValueDel !== null) {
            const a = this.props.txtFieldValueDel

            let t = [
            ];
            let flag = false
            this.state.txtFieldValueFields.forEach((item) => {

                if (item.value === a[0].value) {
                    flag = true
                } else if (flag === false) {
                    t.push(item)
                } else {
                    flag = false
                }
            })
            if (t.length === 0) t.push({ value: '', type: TI_TYPES.TEXT });

            t = processArray(t);
            this.setState({
                txtFieldValueFields: t

            });

            dispatchSetCustomerData(null)
        }


    }

    setData(r) {
        // 
        var t = []
        if (r.task !== "") {
            let Id = null;
            for (const id in this.props.tasks) {
                if (this.props.tasks[id].text === r.task) {
                    Id = id;
                    break;
                }
            }
            t.push({ value: "*** " + this.props.tasks[Id].text.replace(/\r\n/g, ' ') + " ***", type: TI_TYPES.TASK, id: Id.toString() });
            t.push({ value: ' ', type: TI_TYPES.TEXT });
        }
        if (r.customer !== "" && r.customer !== null && r.task === "") {
            let Id = null;
            for (const id in this.props.customers) {
                if (this.props.customers[id].name === r.customer) {
                    Id = id;
                    break;
                }
            }
            t.push({ value: "*** " + this.props.customers[Id].name + " ***", type: TI_TYPES.CUSTOMER, id: Id.toString() });
            t.push({ value: ' ', type: TI_TYPES.TEXT });
        }
        if (r.type !== "" && r.type !== "FA") {
            let Id = null;
            let name = "";
            for (const i in this.props.taskTypes) {
                // 
                if (this.props.taskTypes[i].alias === r.type) {
                    Id = i;
                    name = this.props.taskTypes[i].name
                    break;
                }
            }
            t.push({ value: "*** " + this.props.taskTypes[Id].name + " (" + r.type + ")" + " ***", type: TI_TYPES.TASK_TYPE, id: r.type });
            t.push({ value: ' ', type: TI_TYPES.TEXT });
        }
        t.push({ value: "*** " + "⏱️ " + r.hours.toString().replace(".", ",") + " Stunden" + " ***", type: TI_TYPES.HOURS, id: undefined, floatValue: r.hours.toString() });
        t.push({ value: ' ', type: TI_TYPES.TEXT });
        if (r.location !== "") {
            let Id = null;
            let place = ""
            for (const id in this.props.places) {
                if (r.location.startsWith(this.props.places[id].name)) {
                    Id = id;
                    place = this.props.places[id].name
                    break;
                }
            }
            t.push({ value: "*** " + place + " ***", type: TI_TYPES.PLACE, id: Id.toString() });
        }
        if (r.ref !== "") {
            t.push({ value: ' ', type: TI_TYPES.TEXT });
            t.push({ value: "*** " + r.ref + " ***", type: TI_TYPES.REF, id: -1 });
        }
        t.push({ value: ' ' + r.taskdescription, type: TI_TYPES.TEXT });

        t = processArray(t);
        this.setState({
            txtFieldValueFields: t

        });

        this.setState({
            suggestions: []

        });


        this.FILTERED_TASKS = { ...this.props.tasks };

    }
    /**
     * 
     * @param {*} t txt 
     * @param {*} c value to compare
     * @returns This returns an integer value that represents the match points of the two strings
     * the higher the value the better the match
     * it is calculated by the following rules:
     * the word match in general and the chronological match of the words
     * If our texts is 'I Love Pizza' and the first compared string is 'Hello I Love Pizza' And the other is 'I Love Pizza So Much'
     * the latter will have more match points because the first string has a word in front of the matching text
     */
    getStringMatchPoints(t, c) {
        t = t.trim();
        c = c.trim();
        let tTokens = t.toLowerCase().split(' ');
        let cTokens = c.toLowerCase().split(' ');
        let matchPoints = 0;
        // we're filtering the words that are in both texts...
        let filtered = cTokens.filter((token) => tTokens.includes(token));
        // and give them a point for each match...
        matchPoints += filtered.length;
        // comparing the chronological match of the words...
        // If our target text is 'I Love Pizza' 
        // 'I Love Hamburger' would match bettern than 'Hamburger I Love'
        // this basically gives points worth of 2^i+1 for each match in the beginning of the text
        // I Love Hamburger would break the loop after 'I Love' and give 2^2+1 = 5 points
        for (let i = 1; i < tTokens.length && i < cTokens.length; i++) {
            let tTxt = tTokens.slice(0, i).join(' ');
            let cTxt = cTokens.slice(0, i).join(' ');
            if (tTxt === cTxt) {
                matchPoints += Math.pow(2, i + 1) + 1; // +1 since this has to be a little bit more important than the matching backwards
            } else {
                break;
            }
        }
        let counter = 1;
        // This loop does the same as the previous one but from the end of the text
        // With just one difference, that it doesn't add the +1 to the match points
        // this will ensure that a chronological match from the beginning of the text is more important than the one from the end
        for (let i = (tTokens.length > cTokens.length ? cTokens.length : tTokens.length); i >= 0; i--) {
            let tTxt = tTokens.slice(tTokens.length - i).join(' ');
            let cTxt = cTokens.slice(cTokens.length - i).join(' ');
            if (tTxt === cTxt) {
                matchPoints += Math.pow(2, counter);
            } else {
                break;
            }
            counter++;
        }
        let s1 = t.toLowerCase().trim()
        if (s1 === "") return 0
        if (matchPoints === 0 && s1.length > 0) {                 // >1 bedeutet erst ab 2 zeichen
            let s2 = c.toLowerCase().trim().replace(/\r\n/g, ' ')

            if (s1 === s2.substring(0, s1.length)) {
                return 1
            }

            if (this.isPrefixOfWord(s1, s2)) {
                return 1
            }
        }

        return matchPoints;
    }

    isPrefixOfWord = (s1, s2) => {
        const words = s2.split(' ');
        for (let word of words) {
            if (word.startsWith(s1)) {
                return true;
            }
        }
        return false;
    };

    tagOfTypeExistsInText(type) {
        let t = [...this.state.txtFieldValueFields];
        let index = t.findIndex((field) => field.type === type && field.value.match(tagTextMatcher));
        return index !== -1;
    }

    replaceLastCommaWithAnd(str) {
        if (str.includes(',')) {
            const parts = str.split(',');
            parts[parts.length - 1] = 'und ' + parts[parts.length - 1].trim();
            return parts.join(',');
        }
        return str;
    }

    handleActualizeTable = (data) => {

        const { actualizeTable, dispatchSetActualizeTable } = this.props;
        // dispatchSetActualizeTable(data);
        // if (typeof (data !== "number")) {
        //     dispatchSetActualizeTable(data);
        //     this.setState({
        //         txtFieldValueFields: [
        //             { value: "", type: TI_TYPES.TEXT },
        //         ]
        //     });
        //     this.FILTERED_TASKS = { ...this.props.tasks };
        // } else {
        //     dispatchSetActualizeTable(actualizeTable + 1);
        // }
    };


    isValidDayForMonth(year, month, day) {
        let d = new Date(year, month - 1);
        d.setDate(day); // set date function will increase the month if the day exceeds the maximum possible day for the month.
        // example: year=2024, month=5, day=34
        // the month is 'May' however adding 34 days to it will change the month to 'June' and the day to 3, since may has 31 days in total.
        return d.getMonth() + 1 === month; // comparing the month shows, that the day was not within the valid maximum.
    }
    validateAndSend(spellcheckedDescription) {

        var values = this.state.txtFieldValueFields
        var customer = null
        var task = null
        var place = null
        var taskType = null
        var date = null
        var fromdate = null
        var todate = null
        var hours = null
        var ref = null
        var description = ""
        date = this.props.currentDate

        // fromdate = convertToFourDigitYear(date)
        // todate = convertToFourDigitYear(date)
        fromdate = date;
        fromdate.setHours(12);
        todate = date;
        todate.setHours(12);
        console.log("FROMDATE: ", fromdate, " - TODATE: ", todate);
        for (let i = 0; i < values.length; i++) {

            if (values[i].type === TI_TYPES.CUSTOMER) {
                customer = values[i].id;

            }
            if (values[i].type === TI_TYPES.TASK_TYPE) {
                taskType = this.props.taskTypes[values[i].id].alias;

            }
            if (values[i].type === TI_TYPES.PLACE) {
                place = this.props.places[values[i].id].alias;

            }
            if (values[i].type === TI_TYPES.REF) {
                let regex = /\*\*\* (.+?) \*\*\*/;
                let match = values[i].value.match(regex);
                ref = match ? match[1] : null;
                ref = ref.trim();

            }
            if (values[i].type === TI_TYPES.HOURS) {
                let regex = /\d+(,\d+)?/;
                let match = values[i].value.match(regex);
                hours = match ? parseFloat(match[0].replace(',', '.')) : null;

            }
            if (values[i].type === TI_TYPES.TASK) {
                task = values[i].id;
                taskType = this.props.tasks[values[i].id].art;
                customer = this.props.tasks[values[i].id].kunde;

            }
            if (values[i].type === TI_TYPES.TEXT) {
                if (values[i].value !== ' ') {
                    description = description = values[i].value;
                }
                description = description.trim()

            }
            if (values[i].type === TI_TYPES.DATE) {
                if (values[i].value.indexOf("-") > -1) { // we have a date range
                    let fromDateStr = values[i].value.replace(tagReplace, '').replace('🗓', '').replace(/ /g, '').split('-')[0];
                    let toDateStr = values[i].value.replace(tagReplace, '').replace('🗓', '').replace(/ /g, '').split('-')[1];
                    fromdate =new Date(fromDateStr.split(".")[1]+"-"+fromDateStr.split(".")[0]+"-"+fromDateStr.split(".")[2]);
                    fromdate.setHours(12);
                    todate = new Date(toDateStr.split(".")[1]+"-"+toDateStr.split(".")[0]+"-"+toDateStr.split(".")[2]);
                    todate.setHours(12);
                    console.log("TESTDATESTR: ", fromdate," - TO: ",todate);
                    // const parseGermanDate = (dateString) => {
                    //     let parts = dateString.split('.');
                    //     if (parts[2].length === 2) {
                    //         parts[2] = '20' + parts[2];
                    //     }
                    //     let isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
                    //     return isoDateString;
                    // }
                    // fromdate = parseGermanDate(fromDateStr);
                    // todate = parseGermanDate(toDateStr);
                }
            }
        }
        var msg = ""
        if (taskType === "URL" || taskType === "ZA") {
            place = "P";
            customer = BSB_CUSTOMER_NUMBER;
        }
        if (customer === null) {
            msg = msg + "einen Kunden"
            customer = ""
        }
        if (taskType === null) {
            if (msg !== "") {
                msg = msg + ", "
            }
            msg = msg + "eine Auftragsart"
        }
        if (hours === null) {
            if (msg !== "") {
                msg = msg + ", "
            }
            msg = msg + "eine Zeit"
        }

        if (place === null) {

            if (msg !== "") {
                msg = msg + ", "
            }
            msg = msg + "einen Ort"
            place = ""
        }

        if (description === "") {
            switch (taskType) {
                case "ZA":
                    description = "Zeitausgleich"
                    break;
                case "URL":
                    description = "Urlaub"
                    break;
                default:
                    break;
            }
            if (description === "") {
                if (msg !== "") {
                    msg = msg + ","
                }
                msg = msg + "eine Beschreibung"
            }
        }
        if (msg !== "" && this.props.select === null) {
            msg = "Bitte " + msg + " eingeben !"
            msg = this.replaceLastCommaWithAnd(msg)
            Msg(msg)
            this.setState({ legendError: true });
            return;
        }
        if (ref === null) ref = ""
        var data = {
            "from": fromdate,
            "to": todate,
            "customer": customer,
            "place": place,
            "task": task,
            "taskType": taskType,
            "ref": ref,
            "description": description,
            "time": hours,
        }

        if (taskType === "URL" && this.props.select === null) {

            var currentDate = this.props.currentDate;
            var timetowork = this.props.daysOfMonth[currentDate]?.soll_zeit ?? 0
            var timeworked = this.props.daysOfMonth[currentDate].ist_zeit
            if (fromdate === todate && (hours === null || hours > timetowork - timeworked)) {
                hours = timetowork - timeworked
            }
            if (fromdate !== todate) {
                hours = null
            } else {
                if (this.props.daysOfMonth[currentDate].working_day_status !== "workingday") {
                    Msg("Dieser Tag ist kein Arbeitstag !")
                    return
                }
            }
            data = {
                "hours": hours,
                "from": fromdate,
                "to": todate,
                "date": currentDate
            }

            var rowsPromise = addVacation(data);
            rowsPromise.then((rows) => {

                this.handleActualizeTable(rows)

            });
        } else {
            data = {
                "date": date,
                "from": fromdate,
                "to": todate,
                "customer": customer,
                "place": place,
                "task": task,
                "taskType": taskType,
                "ref": ref,
                "description": spellcheckedDescription != null ? spellcheckedDescription : description,
                "time": hours,
            }
            // 
            this.state.lastData = { ...data } // WTH IS THAT?!
            this.setState({ isLoading: true })
            this.doSpellCheckIfRequired(description, spellcheckedDescription).then(spellCheckedText => {
                if (spellCheckedText !== null) {
                    // This automatically opens the dialog... Which I actually don't find that good, to use a hook (useEffect) to open a dialog
                    // Since this can't be understood by just looking at the code
                    this.setState({ textDialogData: { originalText: description, spellCheckedText: spellCheckedText }, isLoading: false })
                } else {
                    console.log("DOING ADD / UPDATE....")
                    if (this.props.select === null) {
                        console.log("DOING ADD...");
                        addRow(data).then((newData) => {
                            this.props.onAddOrUpdate();
                            this.setState({
                                txtFieldValueFields: [
                                    { value: "", type: TI_TYPES.TEXT },
                                ]
                            });
                            // let dailyRows = [...this.props.rows];
                            // dailyRows.push(newData.rows);
                            // this.props.updateDailyRows(dailyRows)
                            // this.handleActualizeTable(newData.rows);
                            this.setState({ isLoading: false });
                        }).catch((error) => {
                            console.log("ERROR WHILE ADDROW: ", error);
                            Msg('Fehler beim Speichern des Eintrags.');
                            this.setState({ isLoading: false });
                        })
                    } else {
                        updateRow(data, this.props).then((data) => {
                            this.props.onAddOrUpdate();
                            this.setState({
                                txtFieldValueFields: [
                                    { value: "", type: TI_TYPES.TEXT },
                                ]
                            });
                        }).catch((error) => {
                            Msg('Fehler beim Speichern des Eintrags.');
                        })
                        
                    }
                }
            }).catch(e => { // this actually can't be reached, since the promise is always resolved
                console.error("Internal error while spellcheck: ", e);
            })


        }
    }

    doSpellCheckIfRequired(description, spellCheckedText) {
        return new Promise((resolve, reject) => {
            if (this.props.spellCheck) {
                if(spellCheckedText == null){
                    fetch(ip_adress + ':3001/spellcheck', { credentials: "include", body: JSON.stringify({ description: description }), method: 'POST', headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            toast.error(`Fehler bei der KI-Unterstützung (${response.status}).`);
                            return null;
                        }
                    }).then(data => {
                        if (data) {
                            resolve(data.spellCheckedText);
                        } else {
                            toast.error("Fehler bei der KI-Unterstützung.");
                            resolve(null);
                        }
                    }).catch(e => {
                        console.error("Error while spellcheck: ", e);
                        toast.error("Fehler bei der KI-Unterstützung.");
                        resolve(null);
                    })
                }else{
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        })
    }

    scrollToEnd() {
        var element = 0
        setTimeout(() => {
            element = this.textInput.current
            if (element) {
                element.scrollLeft = 5000
                element.selectionStart = element.value.length
                element.selectionEnd = element.value.length

            }
        }, 0);


    }



    /**
     * scans and creates suggestions based on the current text
     */
    scanTextsForTagslet() {
        let suggestions = [];
        var isvacation = this.vacationGiven(this.state.txtFieldValueFields)
        var iscomptime = this.compType(this.state.txtFieldValueFields)


        let currentCaretIndex = this.getIndexAtCaretPosition(this.textInput.current.selectionStart);
        if (currentCaretIndex !== -1) {

            if (this.state.txtFieldValueFields[currentCaretIndex].value.length > 0) {
                let myCurrentTxt = this.state.txtFieldValueFields[currentCaretIndex].value.toLowerCase()
                //
                if (!this.tagOfTypeExistsInText(TI_TYPES.REF && !isvacation)) {
                    const regex = /--(.*?)--|(--.*)/;
                    const matches = myCurrentTxt.match(regex);
                    if (matches) {
                        const extractedString = (matches[1] || matches[2]).replace("--", "").trim();
                        if (extractedString.length > 0) {
                            suggestions.push({ value: " " + extractedString + " ", matchLevel: 9999, type: TI_TYPES.REF, id: -1 });
                        }
                    }
                }
                if (!this.tagOfTypeExistsInText(TI_TYPES.DATE)) {

                    let match = myCurrentTxt.match(DATE_PATTERN);
                    if (match) {

                        let m = match[0].replace("🗓 ", "")
                        suggestions.push({ value: "🗓 " + m.trim() + " ", matchLevel: 9999, type: TI_TYPES.DATE });
                    }

                }

                if (!this.tagOfTypeExistsInText(TI_TYPES.CUSTOMER) && !this.tagOfTypeExistsInText(TI_TYPES.TASK) && !isvacation && !iscomptime) {
                    Object.values(this.props?.customers).forEach((customer, index) => {

                        let matchPoints = this.getStringMatchPoints(myCurrentTxt, customer.name.toLowerCase());
                        if (matchPoints > 0) {
                            suggestions.push({ value: customer.name, matchLevel: matchPoints, type: TI_TYPES.CUSTOMER, id: Object.keys(this.props?.customers)[index] });
                        }
                    })
                }

                if (!this.tagOfTypeExistsInText(TI_TYPES.HOURS)) {
                    const match = myCurrentTxt.match(HOURS_PATTERN);
                    if (match) {
                        suggestions.push({ matchLevel: 9999, value: "⏱️ " + (match[2]) + " " + HOURS_LABEL + " ", floatValue: parseFloat((match[2] + (match[3] ? ',' + match[3] : '')).replace(",", ".")), type: TI_TYPES.HOURS });
                    }
                }
                if (!this.tagOfTypeExistsInText(TI_TYPES.PLACE) && !isvacation && !iscomptime) {
                    Object.values(this.props?.places).forEach((place, index) => {
                        let matchPoints = this.getStringMatchPoints(myCurrentTxt, place.name.toLowerCase());
                        matchPoints += this.getStringMatchPoints(myCurrentTxt, place.alias.toLowerCase());
                        if (matchPoints > 0) {
                            suggestions.push({ value: place.name, matchLevel: matchPoints, type: TI_TYPES.PLACE, id: Object.keys(this.props?.places)[index] });
                        }
                    });
                }

                if (!this.tagOfTypeExistsInText(TI_TYPES.TASK) && !isvacation && !iscomptime) {
                    Object.values(this.FILTERED_TASKS).forEach((task, index) => {
                        let matchPoints = this.getStringMatchPoints(myCurrentTxt, task.text.toLowerCase());
                        if (matchPoints > 0) {
                            suggestions.push({ value: task.text, matchLevel: matchPoints, type: TI_TYPES.TASK, matchcode: task.matchcode, id: Object.keys(this.FILTERED_TASKS)[index] });
                        }
                    });
                }
                if (!this.tagOfTypeExistsInText(TI_TYPES.TASK_TYPE) && !this.tagOfTypeExistsInText(TI_TYPES.TASK) && !isvacation && !iscomptime) {
                    Object.values(this.props.taskTypes).forEach((taskType, index) => {
                        let matchPoints = this.getStringMatchPoints(myCurrentTxt, taskType.name.toLowerCase());
                        matchPoints += this.getStringMatchPoints(myCurrentTxt, taskType.alias.toLowerCase());
                        if (matchPoints > 0) {
                            suggestions.push({ value: taskType.name + ` (${taskType.alias})`, matchLevel: matchPoints, type: TI_TYPES.TASK_TYPE, validWithoutTask: taskType.validWithoutTask, id: Object.keys(this.props.taskTypes)[index] });
                        }
                    });
                }
            }
        }

        suggestions.sort((a, b) => b.matchLevel - a.matchLevel);
        this.setState({ suggestions: [...suggestions] });
    }
    /**
     * This method filters the tasks for a specific customer
     * if the customer id is -1 or not provided, all tasks are shown, so the filter is removed
     * @param {*} customerId the customer id to filter the tasks for
     */
    filterTasksForCustomer(customerId = -1) {

        customerId = parseInt(customerId);
        if (customerId !== -1) {
            this.FILTERED_TASKS = Object.keys(this.props?.tasks).reduce((acc, key, index) => {
                if (index < 5) {


                }
                if (this.props?.tasks[key].kunde === customerId) {
                    acc[key] = this.props?.tasks[key];
                }
                return acc;
            }, {});

        } else {
            this.FILTERED_TASKS = { ...this.props?.tasks };
        }
    }


    /**
     * this is our main event, where we have to handle the text input and all key events
     * @param {*} evt 
     */
    onTextChange(evt) {
        const caretStart = evt.target.selectionStart;
        const caretEnd = evt.target.selectionEnd;

        if (evt.ctrlKey || evt.metaKey) { // this area is for the ctrl key metaKey is for mac

            switch (evt.nativeEvent.key) {
                case 'x':
                    evt.preventDefault();
                    // get rid of the preventDefault() and 
                    // do the same logic as delete / backspace here where the caretStart != caretEnd

                    break;
                // case ' ':
                //     
                //     if(this.state.txtFieldValueFields.some(txtObj => txtObj.type === TI_TYPES.CUSTOMER)){}
                //     break;
                case 'v':
                    if (navigator.clipboard === undefined) {
                        break
                    }
                    navigator.clipboard.readText().then(clipText => {

                        if (evt.target.selectionStart !== evt.target.selectionEnd) {
                            let startIndex = this.getIndexAtCaretPosition(evt.target.selectionStart);
                            let endIndex = this.getIndexAtCaretPosition(evt.target.selectionEnd);
                            let t = [...this.state.txtFieldValueFields];
                            if (startIndex == endIndex) {
                                t[startIndex].value = t[startIndex].value.replace(tagAndUntagReplacer).substring(0, this.getSectionCaretIndexPosition(evt.target.selectionStart)) + clipText + t[startIndex].value.replace(tagAndUntagReplacer).substring(this.getSectionCaretIndexPosition(evt.target.selectionEnd));
                            } else {
                                let startSectionCaretPosition = this.getSectionCaretIndexPosition(evt.target.selectionStart);
                                let endSectionCaretPosition = this.getSectionCaretIndexPosition(evt.target.selectionEnd);
                                let newString = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, startSectionCaretPosition) + clipText + t[endIndex].value.replace(tagAndUntagReplacer, '').substring(endSectionCaretPosition);
                                t.splice(startIndex, endIndex - startIndex + 1, { value: newString, type: TI_TYPES.TEXT });
                                let concattedValue = t[startIndex].value;
                                if (t[startIndex + 1]) {
                                    if (!t[startIndex + 1].value.match(tagTextMatcher) && !t[startIndex + 1].value.match(untaggedTextMatcher)) {
                                        t.splice(startIndex, 2, { value: t[startIndex].value.concat(t[startIndex + 1].value), type: TI_TYPES.TEXT });
                                        // concattedValue += t[startIndex+1].value;
                                    }
                                }
                                if (t[startIndex - 1]) {
                                    if (!t[startIndex - 1].value.match(tagTextMatcher) && !t[startIndex - 1].value.match(untaggedTextMatcher)) {
                                        // concattedValue = t[startIndex-1].value + concattedValue;
                                        t.splice(startIndex - 1, 2, { value: t[startIndex - 1].value.concat(t[startIndex].value), type: TI_TYPES.TEXT });
                                    }
                                }
                            }
                            t = processArray(t);
                            this.setState({
                                txtFieldValueFields: [...t], legendError: false,
                            }, () => {
                                evt.target.selectionStart = caretStart + clipText.length;
                                evt.target.selectionEnd = caretStart + clipText.length;
                                const input = evt.target;
                                const caretPos = input.selectionStart;
                                const inputRect = input.getBoundingClientRect();

                                // Check if the caret position is outside the visible area
                                if (caretPos * 10 > inputRect.width) {
                                    // Calculate the new scroll position
                                    const newScrollLeft = caretPos * 10 - inputRect.width + 10;

                                    // Update the scroll position
                                    input.scrollLeft = newScrollLeft;

                                    // Prevent the default behavior of the event
                                    evt.preventDefault();
                                }
                            })
                        } else {
                            let stateIndex = this.getIndexAtCaretPosition(caretStart);
                            let t = [...this.state.txtFieldValueFields];
                            t[stateIndex].value = t[stateIndex].value.replace(tagAndUntagReplacer, '');
                            t[stateIndex].value = t[stateIndex].value.substring(0, this.getSectionCaretIndexPosition(evt.target.selectionStart)) + clipText + t[stateIndex].value.substring(this.getSectionCaretIndexPosition(evt.target.selectionStart));
                            t = processArray(t);
                            this.setState({
                                txtFieldValueFields: [...t], legendError: false,
                            })
                        }
                    })
                    break;
                default:
                    break;
            }
        } else if (evt.nativeEvent.key === 'ArrowLeft' || evt.nativeEvent.key === 'ArrowRight' || evt.nativeEvent.key === 'ArrowUp' || evt.nativeEvent.key === 'ArrowDown') {
            // do the default...
            if (this.state.suggestionFocusIndex <= 0 && evt.nativeEvent.key === 'ArrowDown' && this.state.suggestions.length > 0) {
                this.setState({
                    suggestionFocusIndex: 0
                });
            } else if (this.state.suggestionFocusIndex >= 0 && evt.nativeEvent.key === 'ArrowUp') {
                evt.preventDefault();
                this.setState({
                    suggestionFocusIndex: -1
                });
            } else if (this.state.suggestionFocusIndex >= 0) {
                evt.preventDefault();
                switch (evt.nativeEvent.key) {
                    case 'ArrowRight':
                        if (this.state.suggestions.length - 1 >= this.state.suggestionFocusIndex + 1) {
                            this.setState({
                                suggestionFocusIndex: this.state.suggestionFocusIndex + 1
                            });
                        }
                        break;
                    case 'ArrowLeft':
                        if (this.state.suggestionFocusIndex - 1 >= 0) {
                            this.setState({
                                suggestionFocusIndex: this.state.suggestionFocusIndex - 1
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
            // this prevents our further code from executing...
        } else if (evt.nativeEvent.key === 'Enter') {
            evt.preventDefault();
            evt.stopPropagation();
            this.validateAndSend();
            // here we have to fire an event to submit the form...
        } else if (evt.nativeEvent.key === 'Tab') {
            evt.preventDefault();
            if (this.state.suggestions.length > 0) {
                this.insertSuggestion(evt.target);
            }
        } else if (evt.nativeEvent.key === 'End') {
            evt.target.scrollLeft = 5000
        } else if (evt.nativeEvent.key === 'Home') {
            evt.target.scrollLeft = 0
        }
        else { // this area is for normal text input
            evt.preventDefault();
            // allowedCharactersPattern is a regex pattern that allows only the characters for the german keyboard layout...
            if (allowedCharactersPattern.test(evt.nativeEvent.key)) {
                let t = [...this.state.txtFieldValueFields];

                if (caretStart !== caretEnd) { // if there is a selection (which is the case when the start and end positions of the caret is not equal), delete the selection before inserting the new text
                    let startIndex = this.getIndexAtCaretPosition(caretStart); // since the values are stored in an array, we have to get the index of the array where the caret position is located or ends
                    let endIndex = this.getIndexAtCaretPosition(caretEnd);
                    if (startIndex !== endIndex) { // there are not in the same section, so we have to delete whole sections in that case
                        let startSectionCaretPosition = this.getSectionCaretIndexPosition(evt.target.selectionStart);
                        let endSectionCaretPosition = this.getSectionCaretIndexPosition(evt.target.selectionEnd);
                        // this builds the text that will be inserted at the caret position and deletes the selected text...
                        let newString = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, startSectionCaretPosition) + evt.nativeEvent.key + t[endIndex].value.replace(tagAndUntagReplacer, '').substring(endSectionCaretPosition);

                        // splice at start index, delete the number of elements from start index to end index and insert the new string at the start index
                        // Korrektur von Oli 03.05.24 weil Frontend-Absturz
                        // vorher
                        // t.value.splice(startIndex, endIndex - startIndex + 1, newString);

                        try {
                            t.splice(startIndex, endIndex - startIndex + 1, {
                                "value": newString,
                                "type": TI_TYPES.TEXT
                            });

                        } catch (error) {

                        }

                    } else {

                        if (evt.nativeEvent.key === 'Space' && (t[startIndex].value.match(tagTextMatcher) || t[startIndex].value.match(untaggedTextMatcher))) {
                            t.push({ value: ' ', type: TI_TYPES.TEXT });
                        } else {
                            // Korrektur von Oli 03.05.24 weil sonst zb. "x" später als customer übergeben wird

                            var newString = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, this.getSectionCaretIndexPosition(evt.target.selectionStart)) + evt.nativeEvent.key + t[startIndex].value.replace(tagAndUntagReplacer, '').substring(this.getSectionCaretIndexPosition(evt.target.selectionEnd));
                            t.splice(startIndex, 1, {
                                "value": newString,
                                "type": TI_TYPES.TEXT
                            });
                            // vorher
                            //t[startIndex].value = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, this.getSectionCaretIndexPosition(evt.target.selectionStart)) + evt.nativeEvent.key + t[startIndex].value.replace(tagAndUntagReplacer, '').substring(this.getSectionCaretIndexPosition(evt.target.selectionEnd));
                        }
                    }
                } else { // here we go with the insertion of the text at the caret point since there is no selection...
                    let startIndex = this.getIndexAtCaretPosition(caretStart);
                    let sectionIndex = this.getSectionCaretIndexPosition(caretStart);
                    // Korrektur von Oli 03.05.24 weil sonst zb. "x" später als customer übergeben wird
                    // vorher
                    // t[startIndex].value = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, sectionIndex) + evt.nativeEvent.key + t[startIndex].value.replace(tagAndUntagReplacer, '').substring(sectionIndex);
                    var newString = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, sectionIndex) + evt.nativeEvent.key + t[startIndex].value.replace(tagAndUntagReplacer, '').substring(sectionIndex);
                    t.splice(startIndex, 1, {
                        "value": newString,
                        "type": TI_TYPES.TEXT
                    });

                }
                this.setState({ txtFieldValueFields: [...t], legendError: false }, () => {
                    evt.target.selectionStart = caretStart + 1;
                    evt.target.selectionEnd = caretEnd + 1;
                    const input = evt.target;
                    const caretPos = input.selectionStart;
                    const inputRect = input.getBoundingClientRect();

                    // Check if the caret position is outside the visible area
                    if (caretPos * 10 > inputRect.width) {
                        // Calculate the new scroll position
                        const newScrollLeft = caretPos * 10 - inputRect.width + 10;

                        // Update the scroll position
                        input.scrollLeft = newScrollLeft;

                        // Prevent the default behavior of the event
                        evt.preventDefault();
                    }

                    this.scanTextsForTagslet();
                });
            }
            const indexForCaretStart = this.getIndexAtCaretPosition(caretStart);
            if (evt.nativeEvent.key === 'Backspace' || evt.nativeEvent.key === 'Delete') { // backspace = remove previous key, delete = remove next key
                if (caretStart === caretEnd) { // here we have a sinlge caret position which deletes either previous or next character...
                    let t = [...this.state.txtFieldValueFields];
                    let deleteIndexFactor = evt.nativeEvent.key === 'Backspace' ? -1 : 0;
                    if (indexForCaretStart !== -1) {
                        let delIndex = this.getSectionCaretIndexPosition(caretStart);
                        if (delIndex !== -1) {
                            if (evt.nativeEvent.key !== 'Delete' || delIndex !== t[indexForCaretStart].value.replace(tagAndUntagReplacer, '').length) {
                                t[indexForCaretStart].value = t[indexForCaretStart].value.replace(tagAndUntagReplacer, '');
                                t[indexForCaretStart].value = t[indexForCaretStart].value.substring(0, delIndex + deleteIndexFactor) + t[indexForCaretStart].value.substring(delIndex + 1 + deleteIndexFactor);
                            } else {
                                if (t[indexForCaretStart + 1]?.value.length > 0) {
                                    t[indexForCaretStart + 1].value = t[indexForCaretStart + 1].value.replace(tagAndUntagReplacer, '');
                                    if (t[indexForCaretStart + 1].value.length == 1) { // if the next element has only one character, remove the whole element...
                                        t.splice(indexForCaretStart + 1, 1);
                                    } else { // if not remove the first character of the next element...
                                        t[indexForCaretStart + 1].value = t[indexForCaretStart + 1].value.substring(1);
                                    }
                                }
                            }
                            this.setState({
                                txtFieldValueFields: [...t], legendError: false,
                            }, () => {
                                evt.target.selectionStart = caretStart + deleteIndexFactor;
                                evt.target.selectionEnd = caretEnd + deleteIndexFactor;
                                this.scanTextsForTagslet();
                            })
                        }
                    }
                } else {
                    let startIndex = this.getIndexAtCaretPosition(caretStart);
                    let endIndex = this.getIndexAtCaretPosition(caretEnd);
                    let t = [...this.state.txtFieldValueFields];
                    let startSectionCaretPosition = this.getSectionCaretIndexPosition(caretStart);
                    let endSectionCaretPosition = this.getSectionCaretIndexPosition(caretEnd);
                    if (startIndex !== endIndex) {
                        let newString = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, startSectionCaretPosition) + t[endIndex].value.replace(tagAndUntagReplacer, '').substring(endSectionCaretPosition);
                        let newType = t[startIndex].type;
                        t.splice(startIndex, endIndex - startIndex + 1, { value: newString, type: newType });
                    } else {
                        t[startIndex].value = t[startIndex].value.replace(tagAndUntagReplacer, '').substring(0, startSectionCaretPosition) + t[startIndex].value.replace(tagAndUntagReplacer, '').substring(endSectionCaretPosition);
                    }
                    this.setState({
                        txtFieldValueFields: [...t], legendError: false,
                    }, () => {
                        evt.target.selectionStart = caretStart;
                        evt.target.selectionEnd = caretStart;
                        this.scanTextsForTagslet();
                    })
                }
            }

        }

        if (!this.state.txtFieldValueFields.some(item => item.type === TI_TYPES.CUSTOMER)) {

            this.FILTERED_TASKS = { ...this.props.tasks };
        }
        // this is a workaround for safari. The onScroll event runs on every other browser that had been run tests on (chrome, opera, firefox).
        // reason for the workaround is, that calling preventDefault on safari even suppresses sequencing events like onChange etc.
        if (window.safari != null) {
            setTimeout(() => {
                // therefore create and fire a manual initiated scroll event
                // the reason doing it within a timeout is to give the DOM time to update, since it might still have the previous state.
                // Thus the scroll position might not be correct for the current shift, if we fire the update too early...  
                const scrollEvent = new Event('scroll', { bubbles: true });
                evt.target.dispatchEvent(scrollEvent);
            }, 100);
        }
    }
    /**
     * this method is used to parse a date string in the format of dd.mm.yyyy to a date object and
     * is being used to change the currentDate props of the component based on the text input
     * @param {*} dateString 
     * @returns 
     */
    parseDateString(dateString) {
        const parts = dateString.split('.');
        let year = parseInt(parts[2], 10);
        if (year < 100) {
            // Handle 2-digit year
            year += 2000; // Assuming years between 00 and 99 belong to the 21st century
        }
        const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed
        const day = parseInt(parts[0], 10);


        return new Date(year, month, day);
    }
    /**
     * this method is used to insert a suggestion into the text field as a tag. It's being called when user clicks or presses tab on a suggestion
     */
    showUnicode(text) {
        const unicodeArray = [];
        for (let i = 0; i < text.length; i++) {
            unicodeArray.push({ code: text.charCodeAt(i), char: text.substring(i, i + 1) });
        }
        return unicodeArray
    }
    filterTaskTypes = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {

            if (data[i].type === TI_TYPES.TASK_TYPE && i < data.length - 1 && data[i + 1].type === TI_TYPES.TEXT) {
                i++;
            } else {
                result.push(data[i]);
            }
        }
        return result;
    };

    filterCustomers = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {

            if (data[i].type === TI_TYPES.CUSTOMER && i < data.length - 1 && data[i + 1].type === TI_TYPES.TEXT) {
                i++;
            } else {
                result.push(data[i]);
            }
        }
        return result;
    };

    filterAllTypes = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {

            if ((data[i].type === TI_TYPES.CUSTOMER || data[i].type === TI_TYPES.TASK || data[i].type === TI_TYPES.PLACE) && i < data.length - 1 && data[i + 1].type === TI_TYPES.TEXT) {
                i++;
            } else {
                result.push(data[i]);
            }
        }
        return result;
    };


    vacationGiven = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].type === TI_TYPES.TASK_TYPE && i < data.length - 1 && data[i].id === "URL") {
                return true;
            }
        }
        return false;
    };
    compType = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].type === TI_TYPES.TASK_TYPE && i < data.length - 1 && data[i].id === "ZA") {
                return true;
            }
        }
        return false;
    }

    insertSuggestion(target, clickIndex) {

        let indexForPosition = this.getIndexAtCaretPosition(target.selectionStart);
        if (indexForPosition !== -1) {

            let suggestion = [...this.state.suggestions];
            let t = [...this.state.txtFieldValueFields];

            let suggestionIndex = clickIndex ? clickIndex : this.state.suggestionFocusIndex != -1 ? this.state.suggestionFocusIndex : 0;

            t[indexForPosition].value = "*** " + suggestion[suggestionIndex].value.replace(/\r\n/g, ' ') + " ***";

            t[indexForPosition].type = suggestion[suggestionIndex].type;
            t[indexForPosition].id = suggestion[suggestionIndex].id;

            t.push({ value: ' ', type: TI_TYPES.TEXT });

            if (indexForPosition !== 0) {
                t.splice(indexForPosition, 0, { value: ' ', type: TI_TYPES.TEXT })
            }
            if (suggestion[0].type === TI_TYPES.DATE) {
                this.props.setCurrentDate(this.parseDateString(suggestion[suggestionIndex].value.split(' ')[1]))
                this.handleActualizeTable(1)
            }
            if (suggestion[0].type === TI_TYPES.TASK) {
                // TODO: Check if task type exists and if so change it to the task type of current task...
                let index = t.findIndex((field) => field.type === TI_TYPES.TASK_TYPE);
                let check = this.FILTERED_TASKS[suggestion[suggestionIndex].id] === undefined

                if (!check) {

                    if (index !== -1) {
                        if (this.FILTERED_TASKS[suggestion[suggestionIndex].id].art !== "FA") {
                            t[index] = { value: "***" + this.props?.taskTypes[this.FILTERED_TASKS[suggestion[suggestionIndex].id].art].name + "(" + this.props?.taskTypes[this.FILTERED_TASKS[suggestion[suggestionIndex].id].art].alias + ")***", type: TI_TYPES.TASK_TYPE, id: this.FILTERED_TASKS[suggestion[0].id].art };
                        }
                    }
                }
            }

            // console.log("--- TXTVALUEFIELDS SET TO => ",t)
            if (suggestion[0].type === TI_TYPES.TASK_TYPE) {
                t[indexForPosition].validWithoutTask = suggestion[suggestionIndex].validWithoutTask;
            }
            if (suggestion[0].type === TI_TYPES.HOURS) {
                t[indexForPosition + (indexForPosition == 0 ? 0 : 1)].floatValue = suggestion[suggestionIndex].floatValue;
            }
            if (suggestion[0].type === TI_TYPES.CUSTOMER) {
                this.filterTasksForCustomer(suggestion[suggestionIndex].id);
            }

            // console.log("--- TXTVALUEFIELDS SET TO => ",t)
            var filteredTask = this.FILTERED_TASKS[suggestion[suggestionIndex].id];
            var isfa = false
            if (filteredTask && typeof filteredTask === 'object' && filteredTask.hasOwnProperty('art')) {
                isfa = this.FILTERED_TASKS[suggestion[suggestionIndex].id].art === "FA"
            }


            // console.log("--- TXTVALUEFIELDS SET TO => ",t)
            if (isfa) {
                t = [...this.filterTaskTypes(t)]
            }

            if (suggestion[suggestionIndex].id === "URL" || suggestion[suggestionIndex].id === "ZA") {
                t = [...this.filterAllTypes(t)]
            }

            console.log("--- TXTVALUEFIELDS SET TO => ", t)
            // if (suggestion[suggestionIndex].type === TI_TYPES.TASK) {
            //     let customerID = this.props.tasks[suggestion[suggestionIndex].id].kunde
            //     var customer = this.props.customers[customerID]
            //     
            //     if (customer !== undefined) {
            //         t = [...this.filterCustomers(t)]
            //         t.push({ value: "*** " + this.props.customers[customerID].name + " ***", type: TI_TYPES.CUSTOMER, id: customerID.toString() });
            //         t.push({ value: ' ', type: TI_TYPES.TEXT });
            //         
            //     }
            // }

            console.log("TXTVALUEFIELDS: ", this.state.txtFieldValueFields)
            console.log("TXTVALUEFIELDS SET TO => ", t)
            t = processArray(t)
            this.setState({
                txtFieldValueFields: [...t],
                suggestions: [],
                suggestionFocusIndex: -1
            }, () => {
                let newPos = this.state.txtFieldValueFields.map(t => t.value).slice(0, indexForPosition == 0 ? 1 : indexForPosition + 2).join('').length;

                target.selectionStart = newPos;
                target.selectionEnd = newPos;

            })

            this.scrollToEnd()
        }
    }
    /**
     * this ensures that the text-area is scrolled together with the text-input
     * */
    shiftDisplayText(input) {
        const txtDisplay = document.getElementById('task-txt-display');
        if (txtDisplay.scrollLeft !== input.scrollLeft) {
            txtDisplay.scrollLeft = input.scrollLeft;
        }
    }
    /** 
     * the text-input has an default event of being scrolled back to the beginning
     * when it loses focus. This ensured, that our text-display is scrolled back 
     * to the beginning as well and is called in the onBlur event of the text-input
     */
    resetLeftScroll() {
        const txtDisplay = document.getElementById('task-txt-display');
        txtDisplay.scrollLeft = 0;
    }

    /**
     * Our texts are being stored with an array so basically they're seperated into sections
     * this returns the index of the array where the caret position is currently located
     * @param {*} caretPos 
     * @returns 
     */
    getIndexAtCaretPosition(caretPos) {
        let currentLength = 0;
        let indexInArray = -1; // Initialize index to -1
        for (let i = 0; i < this.state.txtFieldValueFields.length; i++) {
            const txtFieldValue = this.state.txtFieldValueFields[i].value;
            const joinedText = txtFieldValue.replace(tagAndUntagReplacer, '');
            if (currentLength + joinedText.length >= caretPos) {
                indexInArray = i; // Set index to current index if caretPos falls within current text segment
                break;
            }
            currentLength += joinedText.length;
        }
        return indexInArray;
    }
    /**
     * This method returns the caret position within the section
     * e.g. = ['I', 'Love', 'Pizza'] if we have our caretPos 8 this would mean we
     * are at the index 2 of the array which is the word 'Pizza' and the caret position
     * is 8 - 5 (which is the length of the previous words 'I Love') - 1 (since the caret position is 0 based) = 2
     * @param {*} caretPos 
     * @returns 
     */
    getSectionCaretIndexPosition(caretPos) {
        let totalCharsIterated = 0;
        for (let i = 0; i < this.state.txtFieldValueFields.length; i++) {
            if (caretPos <= totalCharsIterated + this.state.txtFieldValueFields[i].value.replace(tagAndUntagReplacer, '').length) {
                return caretPos - totalCharsIterated;
            }
            totalCharsIterated += this.state.txtFieldValueFields[i].value.replace(tagAndUntagReplacer, '').length;
        }
        return -1;
    }
    /**
     * this method is used to check if the user double clicked on a tag
     * and based on that it either untags the tag or retags the untagged text
     * @param {*} evt 
     */
    checkDisableTag(evt) {
        if (new Date().getTime() - this.lastClickTime < 250) {
            let indexForPosition = this.getIndexAtCaretPosition(evt.target.selectionStart + 1);
            if (indexForPosition !== -1) {
                let t = [...this.state.txtFieldValueFields];
                let valueBeforeChange = t[indexForPosition].value;
                if (t[indexForPosition].value.match(tagReplace)) {
                    // we have to untag it so just replace the tag with the text...
                    t[indexForPosition].value = t[indexForPosition].value.replace(tagReplace, '**');
                    if (t[indexForPosition].type === TI_TYPES.CUSTOMER) {
                        this.filterTasksForCustomer();
                    }
                } else {
                    // we are retagging an untagged text...
                    // therefore we have to check if there are any tags of that type that are active so basically still tagged and if so we have to untag them...

                    t = t.map((field, index) => {
                        if (field.type === t[indexForPosition].type) {
                            field.value = field.value.replace(tagReplace, '**')
                        }
                        return field;
                    });
                    t[indexForPosition].value = t[indexForPosition].value.replace(untaggedReplacer, '***');
                }
                if (valueBeforeChange !== t[indexForPosition].value) { // Only update if value has changed
                    if (evt.target.selectionStart !== evt.target.selectionEnd && this.lastCaretPosition !== -1) {
                        evt.target.selectionStart = this.lastCaretPosition;
                        evt.target.selectionEnd = this.lastCaretPosition;
                    }
                    this.setState({
                        txtFieldValueFields: [...t]
                    })
                }
            }
        }
        this.lastCaretPosition = evt.target.selectionStart;
        this.lastClickTime = new Date().getTime();
    }

    handleSuggestionClick(index) {
        this.insertSuggestion(this.textInput.current, index);
        this.textInput.current.focus();
    }
    /**
     * event handling for clicking on the date pickers text field
     * @param {*} evt 
     */
    onClickPicker(evt) {
        this.setState({ datePickerOpen: true });
    }

    changeDatePickerValue(newValue) {
        this.props.setCurrentDate(newValue.toDate());
        if (this.state.txtFieldValueFields.some(e => e.type === TI_TYPES.DATE)) {
            let index = this.state.txtFieldValueFields.findIndex(e => e.type === TI_TYPES.DATE);
            let t = [...this.state.txtFieldValueFields];
            t[index].value = "*** 🗓 " + newValue.format('DD.MM.YYYY').trim() + " ***";
            this.setState({ datePickerOpen: false, txtFieldValueFields: [...t] });
        } else {
            this.setState({ datePickerOpen: false })
        }
    }
    CustomTextField = React.forwardRef(({ value, onClick }, ref) => (
        <p
            className='unselectable'
            ref={ref}
            onClick={() => { this.onClickPicker() }}
            style={{ width: "5%", textAlign: "center", color: '#353535', verticalAlign: "center", minWidth: "100px", fontFamily: "monospace", fontSize: 14 }}

        // Add any other TextField props you need
        >{("0" + this.props.currentDate.getDate()).slice(-2) + "." + ("0" + (this.props.currentDate.getMonth() + 1)).slice(-2) + "." + this.props.currentDate.getUTCFullYear()}</p>
    ));

    checkData(data, flag) {
        const { customerData, dispatchSetCustomerData } = this.props;



        if (data.type === TI_TYPES.CUSTOMER) {
            if (flag) {

                dispatchSetCustomerData(JSON.stringify(data))
            } else {

                dispatchSetCustomerData(null)
            }

        }
    }
    render() {
        return (
            <div translate='no'>
                <div style={{ width: "100%", display: "flex", marginTop: 20, justifyContent: "center" }}>
                    <div style={{ width: "95%", justifyContent: "center", alignItems: "center" }}>
                        <div className='main-wrapper' style={{ height: "50px", width: "100%", display: "flex", alignItems: "center" }}>
                            <div ref={this.datePickerRef} onClick={(evt) => { if (evt.target === evt.currentTarget) { this.onClickPicker(); } }} style={{ height: "97%", border: "Lightgray 0.5px solid", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, margin: 0, padding: 0, display: "flex", width: "7.5%", minWidth: 120, justifyContent: "center", alignItems: "center" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker className="datePicker"
                                        onClose={() => { this.setState({ datePickerOpen: false }) }}
                                        // slotProps={{dialog: {style:{marginTop: 25, paddingTop: 25}} }}
                                        open={this.state.datePickerOpen}
                                        value={dayjs(this.props.currentDate)}
                                        slots={{ textField: this.CustomTextField }}
                                        slotProps={{ popper: { anchorEl: this.datePickerRef.current } }}
                                        onChange={(newValue) => { this.changeDatePickerValue(newValue) }} />
                                </LocalizationProvider>
                            </div>
                            <div style={{ fontFamily: 'monospace', position: "relative", height: "100%", fontSize: 14, minHeight: 30, display: 'flex', alignItems: "center", overflow: "hidden", width: "90%", /*backgroundColor: 'wheat',*/ }}>
                                <div style={{ height: "100%", width: "100%", backgroundColor: 'transparent', overflowX: 'hidden', display: "flex", alignItems: "center" }}>
                                    <div id='task-txt-display' style={{ width: "100%", height: "100%", marginLeft: "16px", marginRight: "15px", backgroundColor: 'transparent', overflow: 'hidden', display: "flex", alignItems: "center" }}>
                                        {this.state.txtFieldValueFields[0]?.value.length > 0 ?
                                            this.state.txtFieldValueFields.map((data, index) => {
                                                const txtFieldValue = data.value;
                                                if (tagTextMatcher.test(txtFieldValue) || untaggedTextMatcher.test(txtFieldValue)) {
                                                    this.checkData(data, true)
                                                    return <p style={{
                                                        borderRadius: 5, color: '#353535',
                                                        lineHeight: '30px', // this one is required, since emojis would otherwise have a different height...
                                                        verticalAlign: 'middle',
                                                        backgroundColor:
                                                            tagTextMatcher.test(txtFieldValue) ?
                                                                getColor(data.type) :
                                                                untaggedTextMatcher.test(txtFieldValue) ? '#F0F0F0' : 'transparent', whiteSpace: 'pre'
                                                    }}>
                                                        {txtFieldValue.replace(tagReplace, '').replace(untaggedReplacer, '')}
                                                    </p>
                                                }
                                                else {
                                                    this.checkData(data, false)
                                                    return <p style={{ color: '#353535', whiteSpace: 'pre' }}>{txtFieldValue}</p>
                                                }
                                            })
                                            : <p style={{ color: '#656565', whiteSpace: 'pre' }}>Erzähle über deine Tätigkeit...</p> // this is the placeholder text...
                                        }
                                    </div>
                                </div>
                                <input
                                    id="inputfield"
                                    ref={this.textInput}
                                    onKeyDown={(evt) => { this.onTextChange(evt) }}
                                    spellCheck={false}
                                    onScroll={(evt) => { this.shiftDisplayText(evt.target); }}
                                    onClick={(evt) => { this.checkDisableTag(evt) }}
                                    onBlur={() => { this.resetLeftScroll() }}
                                    className='task-textfield'
                                    autoComplete='off'
                                    autoCapitalize='off'
                                    autoCorrect='off'
                                    style={{ lineHeight: "30px", top: 0, left: 0, right: 0, bottom: 0, border: "Lightgray 0.5px solid", borderLeft: "none", borderRight: "none", fontFamily: 'monospace', fontSize: 14, position: "absolute", }}
                                    value={this.state.txtFieldValueFields.map(e => e.value).join('').replace(tagAndUntagReplacer, '')} placeholder='Erzähle über deine Tätigkeit...' />
                            </div>
                            <Button id="PlusButton" variant="contained" sx={{ boxShadow: 3 }} style={{
                                background: '#3e90f6',
                                width: "2.55%",
                                minHeight: "100%",
                                border: "lightgray 0.5px solid",
                                borderLeft: "none",
                                boxShadow: 'none',
                                padding: 0,
                                textTransform: 'none', fontSize: 25,
                                borderTopLeftRadius: 0, borderBottomLeftRadius: 0
                            }}
                                onMouseDown={(evt) => {
                                    evt.preventDefault()
                                }
                                }
                                onClick={(evt) => { this.validateAndSend() }}
                            >
                                {this.props.select === null ?
                                    <Add></Add> :
                                    <img src={require('../media/icons/update.png')} alt="update" style={{ width: 25, height: 25 }} />
                                }
                            </Button>
                        </div>
                        {this.state.suggestions.length > 0 &&
                            <div className='suggestionArea' style={{ display: "flex", width: "100%", overflow: "scroll", marginTop: 10 }}>
                                <TaskInputSuggestions onClick={(index) => { this.handleSuggestionClick(index) }} data={this.state.suggestions} focusIndex={this.state.suggestionFocusIndex} />
                            </div>
                        }
                        <TaskInputLegend callBackFunction={this.props.callBackFunction} customers={this.props.customers} tasks={this.props.tasks} textFieldValues={this.state.txtFieldValueFields} currentDate={this.props.currentDate} legendError={this.state.legendError} />
                    </div>
                    <TextDialogApp data={this.state.textDialogData} onSave={spellCheckedText => { this.validateAndSend(spellCheckedText) }} />
                    <LoadingDialog open={this.state.isLoading} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    actualizeTable: state.actualizeTable,
    currentRow: state.currentRow,
    customerData: state.customerData,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetActualizeTable: (value) => dispatch(setActualizeTable(value)),
    deactivateEditMode: () => { dispatch(setSelection(null)) },
    dispatchSetCustomerData: (value) => dispatch(setCustomerData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskInput);

