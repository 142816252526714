import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material';
import {
  setUpdateTextData
} from './actions';
import { useDispatch } from 'react-redux';
import { Done } from '@mui/icons-material';


const TextDialog = ({ open, onClose, handleClick, originalText, spellCheckedText, setOriginalText, setSpellCheckedText }) => {
  // console.log("ORIGINALTEXT: ", originalText);
  // const [originalTextState, setOriginalText] = useState(originalText);
  // const [spellCheckedTextState, setSpellCheckedText] = useState(spellCheckedText);
  
  return <Dialog
    open={open}
    onClose={(_, reason) => {
      if (reason !== "backdropClick" ) {
        onClose();
      }
    }}
    maxWidth="md"
    fullWidth
    
  >
    <Grid container justifyContent="center">
      <DialogTitle >Textkorrektur</DialogTitle>
    </Grid>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField id="ot"
            label="Originaltext"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={originalText}
            onChange={(e) => setOriginalText(e.target.value)}
            margin="normal"
          />
          <Grid container justifyContent="center">
            <Button onClick={()=>handleClick(originalText)} style={{ textTransform: 'none', background: '#3e90f6' }} variant="contained" startIcon={<Done />}>
              Originaltext Übernehmen
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField id="ct"
            label="Korrigierter Text"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={spellCheckedText}
            onChange={(evt) => setSpellCheckedText(evt.target.value)}
            margin="normal"
          />
          <Grid container justifyContent="center">
            <Button onClick={()=>handleClick(spellCheckedText)} style={{ textTransform: 'none', background: '#3e90f6' }} variant="contained" startIcon={<Done />} >
              Vorschlag Übernehmen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
};

const TextDialogApp = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const [spellCheckedText, setSpellCheckedText] = useState("");
  
  console.log("TEXTDIALOGAPP: ", props.data)
  useEffect(() => {
    if (props.data) {
      console.log("SETTING TEXTS...")
      setOriginalText(props.data.originalText)
      setSpellCheckedText(props.data.spellCheckedText)
      setOpen(true)
    }
  }, [props.data])
  
  const handleClose = () => {
    setOpen(false);
  };

  function handleClick(text){
    handleClose();
    props.onSave(text);
  }
  return (
    <div>
      <TextDialog open={open} setSpellCheckedText={(value) => {setSpellCheckedText(value)}} setOriginalText={(value) => {setOriginalText(value)}} onClose={handleClose} handleClick={handleClick} originalText={originalText}
        spellCheckedText={spellCheckedText} />
    </div>
  );
};

export default TextDialogApp;
