import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { ssetPuser } from "./App";
import { ssetPpassword } from "./App"
import { ip_adress } from "./App";
import { Header } from "./Header";
import { PermissionAreaComponent } from "./hookInjections/PermissionAreaComponent";
import TwoFactorInputField from "./TwoFactorInputField";
import LoadingDialog from "./LoadingDialog";

var twoFactorValue = "";
export function Login() {

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [credentialsCorrect, setCredentialsCorrect] = useState(false);
  
  const LoginValid = () => {

    let userData = credentialsCorrect && twoFactorValue.length == 6 ? // either credentials are correct and was set previously due to correct credentials and the twoFactorValue has proper size
      { // in that case we call login with our two factor auth secret in order to login
        twofasecret: twoFactorValue
      }
      : { // otherwise we have to make the preliminary login attempt to check the credentials first.
        'user': user,
        'password': password
      }
    
    setIsLoading(true)
    fetch(ip_adress + ':3001/login', { method: 'POST', credentials: 'include', body: JSON.stringify(userData), headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        if (response.ok) {
          setIsLoading(false)
          if (credentialsCorrect) {
            
            setCredentialsCorrect(true);
            navigate('/home');
            fetch( ip_adress + ':3001/getcustomers', { credentials: 'include', headers: { 'Accept': 'application/json' } })
              .then(response => {
                
                if (response.ok) {
                  return response.json();
                } else {
                  return null;
                }
              }).then(result => {
                if (result != null) {

                }
              }).catch(e => {
                console.error('Error while loading customers: ', e);
              });
          }else {
            setCredentialsCorrect(true);
          }
          // navigate('/home');
        } else {
          setIsLoading(false)
          console.log("Login fehlgeschlagen...");
        }
      })
      .catch(error => {
        console.log("Error:" + error);
        setIsLoading(false)
        setMessage("Serverfehler !!!!");
      });



  }

  function setTwoFactorValue(value) {
    twoFactorValue = value;
    if (twoFactorValue.length == 6) {
      
      LoginValid();
    }
    
  }
  return (
    <PermissionAreaComponent>
      <div>
        <Header title="Login" />
        <Box align="center">
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align='center'>
            <br></br><br></br>Stundenprogramm | BSB Bremer Software & Beratungs GmbH<br></br><br></br>
          </Typography>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align='center' style={{ color: '#FF0000' }}>
            {message}<br></br>
          </Typography>
          <br></br>
          {!credentialsCorrect ?
            <div>
              <TextField label="Benutzername" variant="outlined" onChange={(e) => setUser(e.target.value)}
                sx={{ boxShadow: 3 }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const el = document.getElementById("password")
                    el.focus()
                  }
                }}
              />
              <br></br><br></br>
              <TextField id="password" label="Passwort" variant="outlined" onChange={(e) => setPassword(e.target.value)}
                sx={{ boxShadow: 3 }}
                type="password"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    LoginValid()
                  }
                }}
              />
            </div>
            :
            <TwoFactorInputField setTwoFactorValue={(val) => { setTwoFactorValue(val) }} />
          }
          <br></br><br></br>
          <Button variant="contained" onClick={LoginValid} sx={{ boxShadow: 3 }} style={{ background: '#3e90f6' }}>
            <Typography style={{ textTransform: 'none' }} >
              Einloggen
            </Typography>
          </Button>
        </Box>
        <LoadingDialog open={isLoading}/>
      </div>
    </PermissionAreaComponent>
  );

}
