import { parse } from "date-fns";

export function isValidDateString(dateString) {
  // Überprüfe, ob der dateString dem Muster dd.mm.yy oder dd.mm.yyyy entspricht
  const regex = /^\d{2}\.\d{2}\.\d{2}(\d{2})?$/;

  // Teste den dateString mit dem regulären Ausdruck
  return regex.test(dateString);
}

export function getBusinessDatesCount(startDate, endDate) {
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}
function getBusinessDates(startDate, endDate) {

  var arr = new Array(0);
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      //      
      arr.push(new Date(curDate));

    }
    curDate.setDate(curDate.getDate() + 1);
  }
  return arr;
}

export function getCurrentDate(separator = '.') {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
}

export function convertDate(d, m, y) {
  m++;
  return `${d}${'.'}${m < 10 ? `0${m}` : `${m}`}${'.'}${y}`;
}

export function formatDate(date) {
  const tag = date.getDate().toString().padStart(2, '0');
  const monat = (date.getMonth() + 1).toString().padStart(2, '0');
  const jahr = date.getFullYear();

  return `${jahr}-${monat}-${tag}`;
}

export function convertToFourDigitYear(dateString) {
  const parts = dateString.split('.');
  const day = parts[0].padStart(2, '0');
  const month = parts[1].padStart(2, '0');
  let year = parts[2];

  // Wenn das Jahr nur zwei Stellen hat, füge "20" am Anfang hinzu
  if (year.length === 2) {
    year = '20' + year;
  }

  return `${year}-${month}-${day}`;
}
export function dateGerToEng(dat) {
  var s = dat;
  var d = s.substring(0, s.indexOf('.'));
  s = s.substring(s.indexOf('.') + 1);
  var m = s.substring(0, s.indexOf('.'));
  var y = s.substring(s.indexOf('.') + 1);
  return y + '-' + m + '-' + d;
}
export function dateEngToGer(dat) {
  var s = dat;
  var y = s.substring(0, s.indexOf('-'));
  s = s.substring(s.indexOf('-') + 1);
  var m = s.substring(0, s.indexOf('-'));
  var d = s.substring(s.indexOf('-') + 1);
  return d + '.' + m + '.' + y;
}
var sj; // Schaltjahr

var ostern; // Ostersonntag: Tag im Jahr

// Regional nicht relevante Feiertage auskommentieren oder löschen !!
function feiertag(day) {
  switch (day) {
    case 1: // Neujahr

    //    case 6:             // Heilige 3 Könige (BW, BY, ST)
    case 121 + sj: // Maifeiertag
    //    case 227 + sj: // Maria Himmelfahrt (BY (nicht überall), SL)
    case 276 + sj: // Tag der dt. Einheit
    case 304 + sj: // Reformationstag (BB, MV, SA, ST,TH)

    //    case 305 + sj:     // Allerheiligen (BW, BY, NW, RP, SL)
    case 358 + sj: // Heiligabend
    case 359 + sj: // 1. Weihnachtstag
    case 360 + sj: // 2. Weihnachtstag
    case 365 + sj: // Silvester
    //    case ostern - 48: // Rosenmontag
    case ostern - 2: // Karfreitag
    case ostern + 1: // Ostermontag
    case ostern + 39: // Christi Himmelfahrt
    case ostern + 50: // Pfingstmontag
      //    case ostern + 60:   // Fronleichnam (BW, BY, HE, NW, RP, SL (SA, TH nicht überall))
      return true;
    default:
      return false;
  }
}
function feiertagStr(day) {
  switch (day) {
    case 1: return "Neujahr"

    //    case 6:   return "Heilige 3 Könige"          // Heilige 3 Könige (BW, BY, ST)
    case 121 + sj: return "Maifeiertag"
    //  case 227 + sj: return  "Himmelfahrt" // (BY (nicht überall), SL)
    case 276 + sj: return "Tag der dt. Einheit"
    case 304 + sj: return "Reformationstag" // (BB, MV, SA, ST,TH)

    //    case 305 + sj:     // Allerheiligen (BW, BY, NW, RP, SL)
    case 358 + sj: return "Heiligabend"
    case 359 + sj: return "1. Weihnachtstag"
    case 360 + sj: return "2. Weihnachtstag"
    case 365 + sj: return "Silvester"
    //  case ostern - 48: return "Rosenmontag"
    case ostern - 2: return "Karfreitag"
    case ostern: return "Ostersonntag"
    case ostern + 1: return "Ostermontag"
    case ostern + 39: return "Christi Himmelfahrt"
    case ostern + 49: return "Pfingstsonntag"
    case ostern + 50: return "Pfingstmontag"
    //    case ostern + 60:   // Fronleichnam (BW, BY, HE, NW, RP, SL (SA, TH nicht überall))

    default:
      return "";
  }
}

// auf Feiertag testen
export function checkHTestuserdays(jetzt) {
  //  var jetzt = new Date();
  var Jahr = jetzt.getFullYear();
  //
  sj = 0;
  var i = Jahr % 4;
  if (i === 0) sj = 1;

  // Die modifizierte Gauss-Formel nach Lichtenberg, gültig bis 2048
  var A = 120 + (19 * (Jahr % 19) + 24) % 30;
  var B = (A + parseInt(5 * Jahr / 4)) % 7;
  ostern = A - B - 33 + sj;

  // Tag des Jahres
  var heutestart = new Date(jetzt.setHours(0, 0, 0, 0));
  var neujahr = new Date(Jahr, 0, 1);
  var difftage = (heutestart - neujahr) / (24 * 60 * 60 * 1000) + 1;
  var tag = Math.ceil(difftage);

  //
  return feiertag(tag);
}

export function holyday(jetzt) {
  //  var jetzt = new Date();
  var Jahr = jetzt.getFullYear();
  //
  sj = 0;
  var i = Jahr % 4;
  if (i === 0) sj = 1;

  // Die modifizierte Gauss-Formel nach Lichtenberg, gültig bis 2048
  var A = 120 + (19 * (Jahr % 19) + 24) % 30;
  var B = (A + parseInt(5 * Jahr / 4)) % 7;
  ostern = A - B - 33 + sj;

  // Tag des Jahres
  var heutestart = new Date(jetzt.setHours(0, 0, 0, 0));
  var neujahr = new Date(Jahr, 0, 1);
  var difftage = (heutestart - neujahr) / (24 * 60 * 60 * 1000) + 1;
  var tag = Math.ceil(difftage);

  //
  return feiertagStr(tag);
}


export function getMonthString(date) {
  var d = new Date(date);

  var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
  var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  var businessDates = getBusinessDates(firstDay, lastDay);
  var s = "";
  for (let i = 0; i < businessDates.length; i++) {
    if (!checkHTestuserdays(businessDates[i])) {
      s += businessDates[i].getDate() + ",";
    }
  }
  if (s !== "")
    s = s.substring(0, s.length - 1);
  return s;

}


export function isBusinessday(date) {

  const dateObject = parse(date, 'dd.MM.yyyy', new Date());
  var d = new Date(dateObject);
  var r = false;
  var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
  var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  var businessDates = getBusinessDates(firstDay, lastDay);
  for (let i = 0; i < businessDates.length; i++) {
    if (+d === +businessDates[i]) {
      if (checkHTestuserdays(businessDates[i])) {
        break;
      }
      r = true;
      break;
    }
  }
  return r;
}

export function isValidDateFormat(dateString) {
  // Definieren Sie das Regex-Muster für das Datum im Format "DD.MM.YYYY" oder "DD.MM.YY"
  const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(?:\d{2})?\d{2}$/;

  // Überprüfen Sie, ob der String dem Regex-Muster entspricht
  return regex.test(dateString);
}
