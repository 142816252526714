import React, { Component } from 'react'
import './Calendar.css';
import Calendar from 'react-calendar';
import { Box } from '@mui/material';
import {
  setActualizeCalendar,
} from './actions';
import { connect } from 'react-redux';
import { ip_adress } from './App';
import { toast } from 'react-toastify';

export class CalendarOverview extends Component {

  handleActualizeCalendar = (date) => {
    const { actualizeCalendar, dispatchSetActualizeCalendar } = this.props;
    this.props.onMonthChange && this.props.onMonthChange(date);
    dispatchSetActualizeCalendar(date.toISOString());

  };
  
  componentDidMount(){

  }
  datesEqual(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
  }
  onChangeDate(changedDate) { // is called on every click on calender
    if (!this.datesEqual(this.props.currentDate, changedDate)) {
      this.props.onChangeDate(changedDate);
    }
  }
  getTileContent(date, view) {
    let dateStr = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2);
    let mData = this.props.monthlyData[dateStr];
    if (mData != null) {

      if (mData.description?.length > 0) {

        return <span style={{ marginLeft: 2.5, fontSize: 10, color: '#979797' }}>{mData.description}</span>
      }

      if (mData.urlaub !== undefined) {

        return <span style={{ marginLeft: 2.5, fontSize: 10, color: '#979797' }}>{"URL"}</span>
      }

    }
  }
  getTileClassName(date, view) {
    date.setHours(12);
    if (date != null) {
      let dateStr = date.toISOString().substring(0,10);

      let mData = this.props.monthlyData[dateStr];
      if (mData != null) {
        if (mData.ist_zeit >= mData.soll_zeit) {
          return 'completed-day'
        } else {
          return 'non-completed-day'
        }
      }
    }
  }



  render() {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Calendar
          value={this.props.currentDate}
          tileContent={({ date, view }) => { return this.getTileContent(date, view) }}
          tileClassName={({ date, view }) => { return this.getTileClassName(date, view) }}
          onViewChange={() => {

          }}
          onActiveStartDateChange={(action, value) => {

            this.handleActualizeCalendar(action.activeStartDate)
          }}

          onChange={(date) => { this.onChangeDate(date) }}></Calendar>

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  actualizeCalendar: state.actualizeCalendar
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetActualizeCalendar: (value) => dispatch(setActualizeCalendar(value))
});


export default connect(mapStateToProps, mapDispatchToProps)(CalendarOverview);