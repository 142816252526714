import { createAction } from '@reduxjs/toolkit';

export const setDate = createAction('SET_DATE');
export const setRows = createAction('SET_ROWS');
export const setSelection = createAction('SET_SELECTION');
export const setHighlightedDays = createAction('SET_HIGHLIGHTEDDAYS');
export const setIsLoading = createAction('SET_ISLOADING');
export const setUser = createAction('SET_USER')
export const setHours = createAction('SET_HOURS')
export const setArtChoice = createAction('SET_ARTCHOICE')
export const setKundeChoice = createAction('SET_KUNDECHOICE')
export const setAuftragChoice = createAction('SET_AUFTRAGCHOICE')
export const setOrtChoice = createAction('SET_ORTCHOICE')
export const setUpdate = createAction('SET_UPDATE')
export const setShowRows = createAction('SET_SHOWROWS')
export const setKopfid = createAction('SET_KOPFID')
export const setWindowwidth = createAction('SET_WINDOWWIDTH')
export const setWordList = createAction('SET_WORDLIST')
export const setContent = createAction('SET_CONTENT')
export const setCalendarDate = createAction('SET_CALENDARDATE')
export const setTransferDate = createAction('SET_TRANSFERDATE')
export const setTransferContent = createAction('SET_TRANSFERCONTENT')
export const setTransferCursor = createAction('SET_TRANSFERCURSOR')
export const setTransferDateMode = createAction('SET_TRANSFERDATEMODE')
export const setTransferUpdateMode = createAction('SET_TRANSFERUPDATEMODE')
export const setRetransfer = createAction('SET_RETRANSFER')
export const setLastTransferContent = createAction('SET_LASTTRANSFERCONTENT')
export const setOriginalDate = createAction('SET_ORIGINALDATE')
export const setPkId = createAction('SET_PKID')
export const setTransferMode = createAction('SET_TRANSFERMODE')
export const setTransferWord = createAction('SET_TRANSFERWORD')
export const setTransferKey = createAction('SET_TRANSFERKEY')
export const setAnchorEl = createAction('SET_ANCHOREL')
export const setCursorPos = createAction('SET_CURSORPOS')
export const setClk = createAction('SET_CLK')
export const setMenuOpened = createAction('SET_MENUOPENED')
export const setMx = createAction('SET_MX')
export const setMy = createAction('SET_MY')
export const setDays = createAction('SET_DAYS')
export const setOSName = createAction('SET_OSNAME')
export const setDatePickerOpen = createAction('SET_DATEPICKEROPEN')
export const setShouldhours = createAction('SET_SHOULDHOURS')
export const setMenuItems = createAction('SET_MENUITEMS')
export const setInsertMode = createAction('SET_INSERTMODE')
export const setErrMsg = createAction('SET_ERRMSG')
export const setxxx = createAction('SET_XXX')
export const setAddRowCounter = createAction('SET_ADDROWCOUNTER')
export const setAddClickCounter = createAction('SET_ADDCLICKCOUNTER')
export const setAlertMsg = createAction('SET_ALERTMSG')
export const setKundenAuftragChoice = createAction('SET_KUNDENAUFTRAGCHOICE')
export const setKundenAuftrag = createAction('SET_KUNDENAUFTRAG')
export const setKunde = createAction('SET_KUNDE')
export const setClick = createAction('SET_CLICK')
export const setTextFieldClick = createAction('SET_TEXTFIELDCLICK')
export const setModalOpen = createAction('SET_MODALOPEN')
export const setDoMarkWordsX = createAction('SET_DOMARKWORDSX')
export const setkeyb = createAction('SET_KEYB')
export const setPrevScrollLeft = createAction('SET_PREVSCROLLLEFT')
export const setLastClickTime = createAction('SET_LASTCLICKTIME')
export const setOriginalWordList = createAction('SET_ORIGINALWORDLIST')
export const setArrayCounter = createAction('SET_ARRRAYCOUNTER')
export const setLastContent = createAction('SET_LASTCONTENT')
export const setLastSpanContent = createAction('SET_LASTSPANCONTENT')
export const setIgnoreWords = createAction('SET_IGNOREWORDS')
export const setIgnoreWords1 = createAction('SET_IGNOREWORDS1')
export const setPrepAnchor = createAction('SET_PREPANCHOR')
export const setMousePos = createAction('SET_MOUSEPOS')
export const setIntervalId = createAction('SET_INTERVALID')
export const setMarkWordsStructure = createAction('SET_MARKWORDSSTRUCTURE')
export const setInputBoxClicked = createAction('SET_INPUTBOXCLICKED')
export const setMWTest = createAction('SET_MWTEST')
export const setShowMarkWords = createAction('SET_SHOWMARKWORDS')
export const setMousePressed = createAction('SET_MOUSEPRESSED')
export const setDeletePos = createAction('SET_DELETEPOS')
export const setKey = createAction('SET_KEY')
export const setLastOriginalText = createAction('SET_LASTORIGINALTEXT')
export const setMenuWords = createAction('SET_MENUWORDS')
export const setInside = createAction('SET_INSIDE')
export const setMenuUse = createAction('SET_MENUUSE')
export const setHtml = createAction('SET_HTML')
export const setMouseDown = createAction('SET_MOUSEDOWN')
export const setShiftDown = createAction('SET_SHIFTDOWN')

export const setMarkedArea = createAction('SET_MARKEDAREA')
export const sethar = createAction('SET_HAR')
export const setDoNotMark = createAction('SET_DONOTMARK')
export const setAreaContent = createAction('SET_AREACONTENT')
export const setAreaSelection = createAction('SET_AREASELECTION')
export const setMScrollLeft = createAction('SET_MSCROLLLEFT')
export const setMarkComp = createAction('SET_MARKCOMP')
export const setDelStartEnd = createAction('SET_DELSTARTEND')
export const setScrollLeft = createAction('SET_SCROLLLEFT')
export const setScrLeft = createAction('SET_SCRLEFT')
export const setPosAndLeft = createAction('SET_POSANDLEFT')
export const setScrollPos = createAction('SET_SCROLLPOS')
export const setKundeStartEnd = createAction('SET_KUNDESTARTEND')
export const setAuftragStartEnd = createAction('SET_AUFTRAGSTARTEND')
export const setDataGridApi = createAction('SET_DATAGRIDAPI')
export const setMarkFocus = createAction('SET_MARKFOCUS')
export const setGridInputFocus = createAction('SET_GRIDINPUTFOCUS')
export const setCheckSelectionID = createAction('SET_CHECKSELECTIONID')
export const setCheckSelectionCounter = createAction('SET_CHECKSELECTIONCOUNTER')
export const setCheckSelection = createAction('SET_CHECKSELECTION')

export const setActualizeHours = createAction('SET_ACTUALIZEHOURS')
export const setActualizeTable = createAction('SET_ACTUALIZETABLE')
export const setActualizeCalendar = createAction('SET_ACTUALIZECALENDAR')
export const setCurrentRow = createAction('SET_CURRENTROW')
export const setSpellCheck = createAction('SET_SPELLCHECK')
export const setUpdateTextData = createAction('SET_UPDATETEXTDATA')
export const setUpdateText = createAction('SET_UPDATETEXT')
export const setControl = createAction('SET_CONTROL')
export const setTxtFieldValue = createAction('SET_TXTFIELDVALUE')
export const setCustomerData = createAction('SET_CUSTOMERDATA')
export const setTxtFieldValueDel = createAction('SET_TXTFIELDVALUEDEL')




// export const setAnchorEl = createAction('SET_ANCHOREL', (payload) => ({
//     x: payload.x,
//     y: payload.y,
//     width: payload.width,
//     height: payload.height,
//   }));
