// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './reducers';

// export const store = configureStore({
//   reducer: rootReducer,
// });

// export default store;
// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Importieren Sie Ihren Root-Reducer

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignoriere die 'setDate'-Aktion von der Serialisierbarkeitsprüfung
        // ignoredActions: ['SET_TXTFIELDVALUEDEL'],
        ignoredActions: [],
        // Ignoriere den Zustandspfad 'date' von der Serialisierbarkeitsprüfung
        // ignoredPaths: ['txtFieldValueDel','actualizeCalendar'],
        ignoredPaths: [],
      },
    }),
});

export default store;
